import PageTopBar from '../components/morecules/PageTopBar'
import React from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../components/atom/ui/accordion'
import FeedbackModal from '../components/morecules/FeedbackModal'

const FaqPage = () => {
  return (
    <div>
      <PageTopBar text='FAQ' />
      <div className='p-4'>
        <Accordion type='multiple'>
          <AccordionItem value='item-1'>
            <AccordionTrigger>How is my data stored?</AccordionTrigger>
            <AccordionContent className='text-gray-500'>
              Your posts/comments are securely encrypted and stored according to
              our security regulations. When you delete your account, all
              associated data will be completely removed. Please use our service
              with confidence.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value='item-2'>
            <AccordionTrigger>How can I contact support?</AccordionTrigger>
            <AccordionContent className='text-gray-500'>
              If you need assistance, please reach out through
              <FeedbackModal desc='Thank you for helping us! We want to hear from you. How can we improve Melonn?'>
                <button className='bg-black text-white rounded-[6px] px-1'>
                  feedback
                </button>
              </FeedbackModal>
              or email us at l.deokhaeng@gmail.com.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value='item-3'>
            <AccordionTrigger className='text-left'>
              What is the purpose of this service?
            </AccordionTrigger>
            <AccordionContent className='text-gray-500'>
              We wanted to create a space where people can share their thoughts
              without worrying about others. At Melonn, there are always friends
              ready to listen, so feel free to write anything without
              hesitation.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value='item-4'>
            <AccordionTrigger className='text-left'>
              Are there times when comments are not posted?
            </AccordionTrigger>
            <AccordionContent className='text-gray-500'>
              In the current version, comments are posted by all friends except
              those you have blocked. However, the time it takes for comments to
              appear can vary from 5 to 60 seconds. Please wait a moment, and if
              comments do not appear, contact us.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  )
}

export default FaqPage
