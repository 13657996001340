import React, { Children, useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'

const MobileLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='flex justify-center content-center w-full h-full min-h-[50vh] bg-white'>
      <div className='bg-white w-full max-w-[600px] relative justify-center'>
        {children}
      </div>
    </div>
  )
}

export default React.memo(MobileLayout)
