import { useInfiniteQuery } from 'react-query'
import { useMemo } from 'react'
import {
  CommentColumns,
  CommentOnlyType,
} from '../services/comment-service/types'
import { CommentService } from '../services/comment-service'

const limitLength = 10

export function useLikes(
  qKey: string,
  columns: CommentColumns[],
  values: (string | boolean)[]
) {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery<CommentOnlyType[]>({
    queryKey: [qKey],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await CommentService.GetLikes(columns, values, pageParam)

      return res
    },
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length + 1

      //상품이 0개이거나 rowsPerPage보다 작을 경우 마지막 페이지로 인식한다.
      return lastPage?.length === 0 || lastPage?.length < limitLength
        ? undefined
        : nextPage
    },
    enabled: true,
    staleTime: 1000 * 10 * 1, // 5분 동안 데이터를 "fresh" 상태로 유지
  })

  const likes = useMemo(() => {
    if (data) return data.pages.flat()
    else return []
  }, [data])

  return {
    likes,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  }
}
