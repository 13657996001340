import React from 'react'
import { Skeleton } from '../atom/ui/skeleton'

const PostSkeletons = () => {
  return (
    <div className='px-2 py-3 flex flex-row w-full'>
      <Skeleton className='w-[36px] rounded-full h-[36px]' />
      <div className='w-[80%]'>
        <Skeleton className='ml-2 w-[40%] h-[20px]' />
        <Skeleton className='mt-2 ml-2 w-full h-[40px]' />
      </div>
    </div>
  )
}

export default React.memo(PostSkeletons)
