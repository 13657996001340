import { CommentDisplayType } from '@/services/comment-service/types'
import { PostDisplayType } from '@/services/post-service/types'
import { UserInformationType } from '@/services/user-service/types'

export const insertUnderComment = async (
  originContent: PostDisplayType,
  commentBody: CommentDisplayType,
  user: UserInformationType,
  pId: string
) => {
  if (!originContent.comments) return

  const DFS = (cmts: CommentDisplayType): CommentDisplayType => {
    if (cmts.comments && cmts.comments.length > 0) {
      return {
        ...cmts,
        comments: [DFS(cmts.comments[0])],
      }
    } else {
      if (cmts.commentId == pId && cmts.user)
        return {
          ...cmts,
          comments: [
            {
              ...commentBody,
              user: user,
            },
          ],
        }
      return cmts
    }
  }

  const totalComments = originContent.comments.map((doc) => {
    return DFS(doc)
  })

  return totalComments
}

