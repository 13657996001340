import { CommentOnlyType } from '../../services/comment-service/types'
import React, { useEffect } from 'react'
import SourceContent from './SourceContent'
import ContentDisplay from '../organisms/ContentDisplay'
import { CommentService } from '../../services/comment-service'
import { Link, useNavigate } from 'react-router-dom'

type ContentOnActivitiesProps = {
  content: CommentOnlyType
}

const ContentOnActivities = ({ content }: ContentOnActivitiesProps) => {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => {
        if (content.postId) navigate(`/post/${content.postId}`)
      }}
      key={content.commentId}
      className={`${content.isRead ? 'bg-white' : 'bg-blue-100/80'} py-2 border-b border-gray-200 cursor-pointer hover:bg-slate-50`}
    >
      <ContentDisplay
        types='comment'
        content={content}
        isLine={false}
        isShort={true}
      />
      {content.comment && content.comment.length > 0 && (
        <SourceContent
          postId={content.postId}
          content={content.comment[0].content}
          imgSrc={content.comment[0].user?.profileImageUrl}
        />
      )}
      {(!content.comment || content.comment.length == 0) &&
        content.post?.content && (
          <SourceContent
            postId={content.postId}
            content={content.post.content}
          />
        )}
    </div>
  )
}

export default React.memo(ContentOnActivities)
