import React, { useCallback, useEffect, useState } from 'react'
import Title from '../components/atom/Title'
import { useUserStore } from '../store/useUserStore'
import { UserService } from '../services/user-service'
import { UserInformationType } from '../services/user-service/types'
import { useQuery } from 'react-query'
import FriendRow from '../components/morecules/FriendRow'
import { Skeleton } from '../components/atom/ui/skeleton'
import FeedbackModal from '../components/morecules/FeedbackModal'
import { MessageCircleMore } from 'lucide-react'
import { useFriendStore } from '../store/useFriendsStore'

const FindPage = () => {
  const { userId } = useUserStore()
  const { friends, setFriends } = useFriendStore()
  const [isLoading, setIsLoading] = useState(true)

  const fetchFriends = useCallback(async () => {
    setIsLoading(true)
    const res = await UserService.GetPersonas(userId)
    if (res) {
      setFriends(res)
    }
    setIsLoading(false)
  }, [userId])

  useEffect(() => {
    fetchFriends()
  }, [])

  return (
    <div className='p-4'>
      <div className='flex flex-row items-center justify-between'>
        <Title text='Friends' />
        <FeedbackModal desc='Thank you for helping us! We want to hear from you. Is there any friend you would like to have added?'>
          <MessageCircleMore color='#515151' className='w-5' />
        </FeedbackModal>
      </div>
      <br />
      {friends &&
        friends.map((item) => {
          return (
            <div key={item.userId}>
              <FriendRow user={item} />
            </div>
          )
        })}
      {isLoading && (
        <>
          <Skeleton className='w-full h-[40px]' />
          <Skeleton className='mt-4 w-full h-[40px]' />
          <Skeleton className='mt-4 w-full h-[40px]' />
          <Skeleton className='mt-4 w-full h-[40px]' />
          <Skeleton className='mt-4 w-full h-[40px]' />
        </>
      )}
    </div>
  )
}

export default React.memo(FindPage)
