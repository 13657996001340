import React, { useEffect, useMemo, useState } from 'react'
import CommentDisplay from '../organisms/CommentDisplay'
import SourceContent from '../morecules/SourceContent'
import { useInView } from 'react-intersection-observer'
import { useComments } from '../../hooks/comments'
import PostSkeletons from '../morecules/PostSkeletons'
import { useNavigate } from 'react-router-dom'

export type PersonaCommentsProsp = {
  personaId: string
  userId: string
}

const PersonaComments = ({ personaId, userId }: PersonaCommentsProsp) => {
  const {
    comments,
    hasNextPage,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  } = useComments(
    personaId + 'comments',
    ['userId', 'ownerId'],
    [personaId, userId]
    )
  const navigate = useNavigate()

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <div>
      {!isLoading &&
        comments &&
        comments.map((cmt) => {
          return (
            <div
              onClick={() => {
                navigate(`/post/${cmt.postId}`)
              }}
              key={cmt.commentId}
              className='pt-1 pb-3 border-b border-gray-200 hover:bg-slate-50 cursor-pointer'
            >
              <CommentDisplay comment={cmt} isLine={false} />
              {cmt.post?.content && (
                <SourceContent postId={cmt.postId} content={cmt.post.content} />
              )}
            </div>
          )
        })}
      <>
        {!isLoading && comments.length == 0 && (
          <div className='sub'>No comments yet</div>
        )}
        {isLoading || isFetchingNextPage ? (
          <>
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
          </>
        ) : (
          <>
            <br />
            {hasNextPage ? (
              <div className='h-10' ref={ref} />
            ) : (
              <div className='sub'></div>
            )}
          </>
        )}
      </>
    </div>
  )
}

export default React.memo(PersonaComments)
