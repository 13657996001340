import { CommentService } from '../../../services/comment-service'
import { useUserStore } from '../../../store/useUserStore'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentDisplay from '../ContentDisplay'
import SourceContent from '../../morecules/SourceContent'
import { useInView } from 'react-intersection-observer'
import { useLikes } from '../../../hooks/likes'
import PostSkeletons from '../../morecules/PostSkeletons'

const ProfileLikes = () => {
  const { userId } = useUserStore()
  const navigate = useNavigate()

  const {
    likes,
    hasNextPage,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  } = useLikes('user_profile_likes', ['ownerId', 'likedByUser'], [userId, true])

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <div>
      {!isLoading &&
        likes &&
        likes.map((cmt) => {
          return (
            <div
              onClick={() => navigate(`/post/${cmt.postId}`)}
              key={cmt.commentId}
              className='py-2 border-b border-gray-200 hover:bg-gray-50 cursor-pointer'
            >
              <ContentDisplay types='comment' content={cmt} isLine={false} />
              {cmt.comment && cmt.comment.length > 0 && (
                <SourceContent
                  postId={cmt.postId}
                  content={cmt.comment[0].content}
                  imgSrc={cmt.comment[0].user?.profileImageUrl}
                />
              )}
              {(!cmt.comment || cmt.comment.length == 0) &&
                cmt.post?.content && (
                  <SourceContent
                    postId={cmt.postId}
                    content={cmt.post.content}
                  />
                )}
            </div>
          )
        })}

      <>
        {!isLoading && likes.length == 0 && (
          <div className='sub'>No liked contents yet</div>
        )}
        {isLoading || isFetchingNextPage ? (
          <>
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
          </>
        ) : (
          <>
            <br />
            {hasNextPage ? (
              <div className='h-10' ref={ref} />
            ) : (
              <div className='sub'></div>
            )}
          </>
        )}
      </>
    </div>
  )
}

export default React.memo(ProfileLikes)
