import { sb } from '../../services/supabase'
import { useSession } from '../../hooks/auth'
import React, { useCallback, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import LoadingScreen from './LoadingScreen'
import { useUserStore } from '../../store/useUserStore'
import { UserService } from '../../services/user-service'
import { UserInformationType } from '../../services/user-service/types'
import { FunnelService } from '../../services/log-service'

const SessionLayout = () => {
  const { isLoading, setSession } = useSession()
  const { setEmail, setImgUrl, setName, setBio, setUserId, userId } =
    useUserStore()
  const navigate = useNavigate()

  const initSession = useCallback(async () => {
    const {
      data: { session },
    } = await sb.auth.getSession()
    setSession(session)
    if (session) {
      setUserId(session.user.id)
      const checkLocaluserId = localStorage.getItem('userId')
      if (!checkLocaluserId) {
        const ch = localStorage.setItem('userId', session.user.id)
      }

      const res = await UserService.GetUser('userId', session.user.id)
      // 유저 정보가 없으면 회원가입
      if (!res || res.length == 0) {
        const body: UserInformationType = {
          userId: session.user.id,
          email: session.user.user_metadata.email,
          name: session.user.user_metadata.name,
          displayName: session.user.user_metadata.name,
          profileImageUrl: session.user.user_metadata.avatar_url,
          registeredAt: new Date().toISOString(),
          lastLoginAt: new Date().toISOString(),
        }
        const registerResponse = await UserService.InsertUser(body)
        setEmail(session.user.user_metadata.email)
        setImgUrl(session.user.user_metadata.avatar_url)
        setName(session.user.user_metadata.name)
        setUserId(session.user.id)

        const checkLogId = sessionStorage.getItem('logId')
        if (checkLogId) {
          const body = {
            logId: checkLogId,
            isSignup: true,
          }
          FunnelService.Update('logId', body)
        }
      } else if (res.length > 0) {
        setEmail(res[0].email)
        setImgUrl(res[0].profileImageUrl)
        setName(res[0].name)
        setBio(res[0].bio ?? '')
      }
    } else {
      navigate('/signin')
    }
  }, [])

  useEffect(() => {
    if (userId) return

    initSession()

    const {
      data: { subscription },
    } = sb.auth.onAuthStateChange(async (_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (isLoading || !userId) {
    return <LoadingScreen />
  }

  return <Outlet />
}

export default React.memo(SessionLayout)
