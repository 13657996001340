import { useUserStore } from '../../store/useUserStore'
import { BlockService } from '../../services/block-service'
import React, { useCallback, useEffect, useState } from 'react'
import CustomAlert from '../organisms/CustomAlert'
import { useQueryClient } from 'react-query'

type BlockButtonProps = {
  blocked: boolean
  personaId: string
  name: string
  onBlock?: (body: any) => void
}

const BlockButton = ({
  blocked,
  personaId,
  name,
  onBlock,
}: BlockButtonProps) => {
  const { userId } = useUserStore()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBlocked, setIsBlocked] = useState(blocked)

  useEffect(() => {}, [blocked])

  const doBlock = useCallback(async () => {
    if (isBlocked) {
      const body = {
        blockerId: userId,
        blockedId: personaId,
      }
      const res = await BlockService.DeleteBlock(body) // this means block=unfollow

      setIsBlocked(false)
      if (onBlock) onBlock(null)
    } else {
      const body = {
        blockerId: userId,
        blockedId: personaId,
        createdAt: new Date().toISOString(),
      }
      const res = await BlockService.InsertBlock(body) // this means follow

      setIsBlocked(true)
      if (onBlock) onBlock(body)
    }
  }, [isBlocked, personaId, userId])

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className={`border border-gray-300 text-[15px] rounded-[8px] w-full p-2 font-medium ${isBlocked ? 'bg-black text-white hover:bg-gray-900' : 'bg-white text-black hover:bg-slate-100'}`}
      >
        {isBlocked ? 'Unblock' : 'Block'}
      </button>
      <CustomAlert
        title={!isBlocked ? `Block ${name}` : `Unblock ${name}`}
        desc={
          !isBlocked
            ? `
              You will no longer receive comments or replies from ${name}, but can still see previous comments/replies from ${name}. You can always unblock ${name}.`
            : `${name} will be able to see your posts/comments/replies again and respond. You can always block ${name} again. `
        }
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onClick={() => {
          doBlock()
          setIsModalOpen(false)
        }}
      />
    </>
  )
}

export default React.memo(BlockButton)
