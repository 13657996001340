import React from 'react'
import { CSSTransition } from 'react-transition-group'

const withAnimation = (WrappedComponent: any) => {
  return function WithAnimation(props: any) {
    return (
      <CSSTransition in={true} appear={true} timeout={300} classNames='fade'>
        <WrappedComponent {...props} />
      </CSSTransition>
    )
  }
}

export default withAnimation
