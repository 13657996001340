import { FunnelType } from '@/services/log-service/types'
import { FunnelService } from '../services/log-service'
import React, { useEffect, useMemo, useState } from 'react'
import { supabase } from '../services/supabase'
import { Line } from 'react-chartjs-2'
// import { CategoryScale, Chart } from 'chart.js'
// Chart.register(CategoryScale)
import 'chart.js/auto'
import { sum } from 'lodash'

const refineByDate = (values: any[], dateBase: string) => {
  const dateUserCounts: any = {}

  values.forEach((data: any) => {
    const generatedTime = new Date(data[dateBase])
    generatedTime.setHours(generatedTime.getHours() - 8)
    const dateString = generatedTime.toISOString().split('T')[0] // 날짜 부분만 추출

    dateUserCounts[dateString] = (dateUserCounts[dateString] || 0) + 1
  })

  return dateUserCounts
}

const AdminPage = () => {
  const [logs, setLogs] = useState<FunnelType[]>([])
  const [dailyUsers, setDailyUsers] = useState<any>(null)
  const [dailyUsages, setDailyUsages] = useState<any>(null)
  const [dailyExUsages, setDailyExUsages] = useState<any>(null)
  const [dailyWUsers, setDailyWUsers] = useState<any>(null)
  const [dailyUUsers, setDailyUUsers] = useState<any>(null)

  const get = async () => {
    const res = await FunnelService.Gets()
    if (res) setLogs(res)
  }

  const alength = useMemo(() => {
    if (logs) return logs.filter((doc) => doc.type == 'A').length
    else return 10
  }, [logs])

  const blength = useMemo(() => {
    if (logs) return logs.filter((doc) => doc.type == 'B').length
    else return 10
  }, [logs])

  const daily = async () => {
    const { data, error } = await supabase
      .from('post')
      .select(
        `
        post_id,
        user_id,
        created_at
        `
      )
      .neq('user_id', 'd93b8c04-52c9-42c3-b2a0-c1725065de9f')
      .order('created_at', { ascending: true })

    const res = await supabase
      .from('user')
      .select(
        `
        user_id,
        registered_at
        `
      )
      .order('registered_at', { ascending: true })


    if (data && data.length > 0 && res.data) {
      const totladatedata = refineByDate(data, 'created_at')
      const totlaUserdata = refineByDate(res.data, 'registered_at')

      const dateUserCounts: any = {}

      const dateStrings: string[] = []
      data.forEach((data: any) => {
        const generatedTime = new Date(data['created_at'])
        generatedTime.setHours(generatedTime.getHours() - 8)
        const dateString = generatedTime.toISOString().split('T')[0] // 날짜 부분만 추출
        if (!dateStrings.includes(dateString)) {
          dateStrings.push(dateString)
        }
      })

      dateStrings.forEach((doc) => {
        if (totlaUserdata[doc]) {
          dateUserCounts[doc] = totladatedata[doc] - totlaUserdata[doc]
        } else {
          dateUserCounts[doc] = totladatedata[doc]
        }
      })

      const dateWUserCounts: any = {}
      data.forEach((dataa: any) => {
        const generatedTime = new Date(dataa['created_at'])
        generatedTime.setHours(generatedTime.getHours() - 8)
        const dateString = generatedTime.toISOString().split('T')[0] // 날짜 부분만 추출
        if (!dateWUserCounts[dateString]) {
          dateWUserCounts[dateString] = [dataa['user_id']]
        } else {
          if (!dateWUserCounts[dateString].includes(dataa['user_id'])) {
            dateWUserCounts[dateString].push(dataa['user_id'])
          }
        }
      })
      const dateWExtractedUserCounts: any = {}
      dateStrings.forEach((doc) => {
        if (dateWUserCounts[doc]) {
          dateWExtractedUserCounts[doc] =
            dateWUserCounts[doc].length - totlaUserdata[doc]
        } else {
          dateWExtractedUserCounts[doc] = dateWUserCounts[doc].length
        }
      })

      setDailyWUsers(dateWUserCounts)
      setDailyUUsers(dateWExtractedUserCounts)

      console.log(dateWUserCounts)
      console.log(totlaUserdata)
      console.log(dateWExtractedUserCounts)

      setDailyExUsages(dateUserCounts)
      setDailyUsers(totlaUserdata)
      setDailyUsages(totladatedata)
    }
  }

  return (
    <div>
      <p className='text-[4em] font-semibold py-20'>Funnel</p>
      <button onClick={() => daily()}>Get daily users</button>
      <button onClick={() => get()}>Get funnel data</button>
      {logs && (
        <div className='grid grid-flow-row gap-4'>
          <div>Test-A</div>
          <div className='grid grid-flow-col gap-1 bg-slate-200'>
            <div className='w-20'>이름</div>
            <div>수</div>
            <div>비율(%)</div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>진입</div>
            <div>{alength}</div>
            <div>100</div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>설명 클릭</div>
            <div>
              {logs.filter((doc) => doc.isClickDesc && doc.type == 'A').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isClickDesc && doc.type == 'A').length /
                alength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>로그인 클릭</div>
            <div>
              {logs.filter((doc) => doc.isClickLogin && doc.type == 'A').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isClickLogin && doc.type == 'A')
                .length / alength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>회원가입</div>
            <div>
              {logs.filter((doc) => doc.isSignup && doc.type == 'A').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isSignup && doc.type == 'A').length /
                alength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>작성클릭</div>
            <div>
              {logs.filter((doc) => doc.isClickWrite && doc.type == 'A').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isClickWrite && doc.type == 'A')
                .length / alength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>포스트 작성</div>
            <div>
              {logs.filter((doc) => doc.isPost && doc.type == 'A').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isPost && doc.type == 'A').length /
                alength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>평균 포스트 수</div>
            <div>
              {sum(
                logs
                  .filter((doc) => doc.type == 'A')
                  .map((item) => {
                    if (item.user && item.user.post)
                      return item.user.post.length
                    else return 0
                  })
              ) / logs.filter((doc) => doc.type == 'A').length}
            </div>
            <div></div>
          </div>
        </div>
      )}
      <br />
      <br />
      {logs && (
        <div className='grid grid-flow-row gap-4'>
          <div>Test-B</div>
          <div className='grid grid-flow-col gap-1 bg-slate-200'>
            <div className='w-20'>이름 </div>
            <div>수</div>
            <div>비율(%)</div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>진입</div>
            <div>{blength}</div>
            <div>100</div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>설명 클릭</div>
            <div>
              {logs.filter((doc) => doc.isClickDesc && doc.type == 'B').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isClickDesc && doc.type == 'B').length /
                blength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>로그인 클릭</div>
            <div>
              {logs.filter((doc) => doc.isClickLogin && doc.type == 'B').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isClickLogin && doc.type == 'B')
                .length / blength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>회원가입</div>
            <div>
              {logs.filter((doc) => doc.isSignup && doc.type == 'B').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isSignup && doc.type == 'B').length /
                blength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>작성클릭</div>
            <div>
              {logs.filter((doc) => doc.isClickWrite && doc.type == 'B').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isClickWrite && doc.type == 'B')
                .length / blength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>포스트 작성</div>
            <div>
              {logs.filter((doc) => doc.isPost && doc.type == 'B').length}
            </div>
            <div>
              {logs.filter((doc) => doc.isPost && doc.type == 'B').length /
                blength}
            </div>
          </div>
          <div className='grid grid-flow-col gap-1'>
            <div className='w-20'>평균 포스트 수</div>
            <div>
              {sum(
                logs
                  .filter((doc) => doc.type == 'B')
                  .map((item) => {
                    if (item.user && item.user.post)
                      return item.user.post.length
                    else return 0
                  })
              ) / logs.filter((doc) => doc.type == 'B').length}
            </div>
            <div></div>
          </div>
        </div>
      )}
      <br />
      <br />
      {dailyUsers && dailyUsages && dailyExUsages && (
        <>
          <div>유저</div>
          <Line
            data={{
              labels: Object.keys(dailyUsers).sort(),
              datasets: [
                {
                  data: dailyUsers,
                  backgroundColor: 'purple',
                },
              ],
            }}
          />
          <div>사용량</div>
          <Line
            data={{
              labels: Object.keys(dailyUsages).sort(),
              datasets: [
                {
                  data: dailyUsages,
                  backgroundColor: 'purple',
                },
              ],
            }}
          />
          <div>사용량 - 유저</div>
          <Line
            data={{
              labels: Object.keys(dailyExUsages).sort(),
              datasets: [
                {
                  data: dailyExUsages,
                  backgroundColor: 'purple',
                },
              ],
            }}
          />
          <div>오늘 작성한 유저의 수</div>
          <Line
            data={{
              labels: Object.keys(dailyWUsers).sort(),
              datasets: [
                {
                  data: dailyWUsers,
                  backgroundColor: 'purple',
                },
              ],
            }}
          />
          <div>오늘 작성한 유저의 수 - 오늘 가입한 유저 수</div>
          <Line
            data={{
              labels: Object.keys(dailyUUsers).sort(),
              datasets: [
                {
                  data: dailyUUsers,
                  backgroundColor: 'purple',
                },
              ],
            }}
          />
        </>
      )}
    </div>
  )
}

export default AdminPage
