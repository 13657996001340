import snakeCase from 'lodash.snakecase'
import {
  deleteData,
  insertData,
  returnSupabase,
  supabase,
  updateData,
  upsertData,
} from '../supabase'
import { FunnelColumns, FunnelQueryType, FunnelType } from './types'

export class FunnelService {
  constructor() {}
  static TABLE_NAME = 'funnel'

  static async Insert(request_data: FunnelType): Promise<any> {
    const result = await insertData(FunnelService.TABLE_NAME, request_data)
    return result
  }

  static async Delete(body: FunnelQueryType) {
    const result = await deleteData(FunnelService.TABLE_NAME, body)

    return result
  }

  static async Gets(
    column?: FunnelColumns,
    value?: string | number | any
  ): Promise<FunnelType[]> {
    const { data, error } = await supabase
      .from(FunnelService.TABLE_NAME)
      .select(
        `
        *,
        user (user_id, post (post_id))
        `
      )
      .filter('user_id', 'not.is', null)

    return returnSupabase(data, error)
  }

  static async Update(primaryKeyName: FunnelColumns, body: FunnelQueryType) {
    const result = await updateData(
      FunnelService.TABLE_NAME,
      primaryKeyName,
      body
    )

    return result
  }
}
