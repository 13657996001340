import { openai } from '../../services/agent'
import _ from 'lodash'
import { UserService } from '../../services/user-service'

export const postProcessComment = (output: string) => {
  let commentText = output.toLowerCase()
  if (commentText.includes('my_comment')) {
    try {
      const parsedOutput = JSON.parse(output);
      return parsedOutput.my_comment || '';
    } catch (error) {
      console.error('Invalid JSON:', error);
      return '';
    }
  }
  if (commentText.includes('comment:')) {
    const commentIndex = commentText.indexOf('comment:')
    commentText = commentText.substring(commentIndex + 'comment:'.length).trim()
  }
  if (commentText.includes('댓글:')) {
    const commentIndex = commentText.indexOf('댓글:')
    commentText = commentText.substring(commentIndex + '댓글:'.length).trim()
  }
  if (commentText[0] == '"') {
    commentText = commentText.slice(1)
  }
  if (commentText[0] == "'") {
    commentText = commentText.slice(1)
  }
  if (commentText[-1] == "'") {
    commentText = commentText.slice(0, -1)
  }
  if (commentText[-1] == "'") {
    commentText = commentText.slice(0, -1)
  }

  return commentText
}

export const getRandomSeconds = (length: number) => {
  const secs: number[] = []
  secs.push(Math.floor(Math.random() * (6000 - 1000 + 1)) + 1000)
  secs.push(Math.floor(Math.random() * (6000 - 1000 + 1)) + 1000)

  if (length > 2) {
    secs.push(Math.floor(Math.random() * (15000 - 5000 + 1)) + 5000)
    secs.push(Math.floor(Math.random() * (15000 - 5000 + 1)) + 5000)

    if (length > 4) {
      for (let i = 0; i < length - 4; i++) {
        const sec = Math.floor(Math.random() * (40000 - 10000 + 1)) + 10000
        secs.push(sec)
      }
    }
  }

  return _.shuffle(secs)
}

export const checkNationality = async (
  userId: string,
  name: string,
  email: string,
  post: string
) => {
  const completion = await openai.chat.completions.create({
    messages: [
      {
        role: 'system',
        content:
          "You are a helpful ai assistant. only return string 'korean' or 'english'",
      },
      {
        role: 'user',
        content: `
Based on the information, Answer whether this user is korean or not.
name is the name of the user, email is the email of the user. post is the first post that the user uploaded in my service.

Information
""
Name : ${name}
Email : ${email}
Post : ${post}
""

only return string 'korean' or 'english'
        `,
      },
    ],
    temperature: 0.8,
    model: 'gpt-4o-mini',
  })

  if (completion.choices[0].message.content) {
    const body = {
      userId: userId,
      nation: completion.choices[0].message.content,
    }
    UserService.UpdateUser('userId', body)
  }

  return completion.choices[0].message.content
}
