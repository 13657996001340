import { Undo2 } from 'lucide-react'
import React from 'react'
import ProfileIcon, { ProfileImage } from '../atom/ProfileIcon'

type SourceContentProps = {
  postId: string
  content: string
  imgSrc?: string
}

const SourceContent = ({ postId, content, imgSrc }: SourceContentProps) => {
  return (
    <div className='flex flex-row pl-[12%] items-center opacity-70'>
      <Undo2 color='#919191' size='18' className='pr-1' />
      <ProfileImage size='sm' imgSrc={imgSrc} />
      <div className='text-[#919191] pl-1 items-center text-[14px] overflow-hidden whitespace-nowrap text-ellipsis max-w-[88%]'>
        you: {content}
      </div>
    </div>
  )
}

export default React.memo(SourceContent)
