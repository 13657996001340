import { useInfiniteQuery } from 'react-query'
import { returnSupabase, supabase } from '../services/supabase'
import snakeCase from 'lodash.snakecase'
import { useMemo } from 'react'
import {
  CommentColumns,
  CommentDisplayType,
  CommentOnlyType,
} from '../services/comment-service/types'
import { CommentService } from '../services/comment-service'

const limitLength = 10

export function useComments(
  qKey: string,
  columns: CommentColumns[],
  values: string[]
) {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery<CommentOnlyType[]>({
    queryKey: [qKey],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await CommentService.GetComments(columns, values, pageParam)

      return res
    },
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length + 1

      //상품이 0개이거나 rowsPerPage보다 작을 경우 마지막 페이지로 인식한다.
      return lastPage?.length === 0 || lastPage?.length < limitLength
        ? undefined
        : nextPage
    },
    enabled: true,
  })

  const comments = useMemo(() => {
    if (data) return data.pages.flat()
    else return []
  }, [data])

  return {
    comments,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  }
}
