import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from '../atom/ui/alert-dialog'
import { useUserStore } from '../../store/useUserStore'
import TextareaAutosize from 'react-textarea-autosize'
import { supabase } from '../../services/supabase'
import Snackbar from './SnackBar'
import { SnackBarTypes } from './SnackBarUI'

type FeedbackModalProps = {
  children: React.ReactNode
  desc?: string
}

const FeedbackModal = ({
  children,
  desc = 'Thank you for helping us! We want to hear from you',
}: FeedbackModalProps) => {
  const { userId, name } = useUserStore()
  const [text, setText] = useState<string>()

  const submitFeedback = async () => {
    if (!text) return

    const body = {
      user_id: userId,
      name: name,
      content: text,
    }
    const { data, error } = await supabase.from('feedback').insert(body)

    if (!data) {
      setText('')
      Snackbar.show({
        text: SnackBarTypes.FEEDBACK,
      })
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger className='cursor-pointer hover:bg-slate-50 py-1 px-2 rounded-[4px]'>
        {children}
      </AlertDialogTrigger>
      <AlertDialogContent className='bg-white/100 w-[90%] rounded-[12px] sm:rounded-[12px] overflow-hidden pb-4 border-none'>
        <AlertDialogHeader>
          <AlertDialogTitle className='text-[20px] font-medium sm:text-center'>
            Feedback
          </AlertDialogTitle>
          <AlertDialogDescription></AlertDialogDescription>
          <div className='text-[15px] sm:text-center'>
            <div>{desc}</div>
            <TextareaAutosize
              className='rounded-[4px] border transition duration-300 ease focus:outline-none focus:border-black/90 mt-4 mb-1 border-gray-300 w-full p-3 resize-none text-[16px]'
              value={text}
              placeholder='Please write any kind of feedback'
              onChange={(e) => setText(e.currentTarget.value)}
            />
            <div className='mb-2 text-gray-400 font-normal text-[14px]'>
              All the feedback prior to July 18th has been reviewed.
            </div>
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter className='justify-end w-full flex flex-row items-center'>
          <AlertDialogCancel className='border-none p-2 px-4 m-0 rounded-[4px] font-normal'>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            className='border-none p-2 px-8 ml-2 bg-black text-white rounded-[4px] hover:bg-gray-800 font-medium'
            onClick={async (e) => {
              await submitFeedback()
            }}
          >
            Submit
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default React.memo(FeedbackModal)
