import { useUserStore } from '../../../store/useUserStore'
import React, { useCallback, useEffect, useState } from 'react'
import ContentDisplay from '../ContentDisplay'
import { Link, useNavigate } from 'react-router-dom'
import { useOnlyPosts } from '../../../hooks/posts'
import { useInView } from 'react-intersection-observer'
import PostSkeletons from '../../morecules/PostSkeletons'

const ProfilePosts = () => {
  const { userId } = useUserStore()
  const navigate = useNavigate()
  const {
    hasNextPage,
    posts,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  } = useOnlyPosts('userId', userId)

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <div>
      {!isLoading &&
        posts &&
        posts.map((post) => {
          return (
            <div
              onClick={() => navigate(`/post/${post.postId}`)}
              key={post.postId}
              className='py-2 border-b border-gray-200 hover:bg-gray-50 cursor-pointer'
            >
              <ContentDisplay types='post' content={post} isLine={false} />
            </div>
          )
        })}
      <>
        {!isLoading && posts.length == 0 && (
          <div className='sub'>No posts yet</div>
        )}
        {isLoading || isFetchingNextPage ? (
          <>
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
          </>
        ) : (
          <>
            <br />
            {hasNextPage ? (
              <div className='h-10' ref={ref} />
            ) : (
              <div className='sub'></div>
            )}
          </>
        )}
      </>
    </div>
  )
}

export default React.memo(ProfilePosts)
