import { CommentDisplayType } from '../../services/comment-service/types'
import { PostDisplayType } from '../../services/post-service/types'
import React, { useEffect, useMemo, useState } from 'react'
import { Heart, MessageCircle } from 'lucide-react'
import { useUserStore } from '../../store/useUserStore'
import { CommentService } from '../../services/comment-service'

export type ContentProps = {
  content: Partial<PostDisplayType> & Partial<CommentDisplayType>
  types: 'post' | 'comment'
}

const ContentBottomBar = ({ content, types }: ContentProps) => {
  const [isLike, setIsLike] = useState<boolean>(false)
  const [likesCount, setLikesCount] = useState<number>(0)

  useEffect(() => {
    setIsLike(content.likedByUser ? true : false)
    setLikesCount(content.likesCount ? content.likesCount : 0)
  }, [content])

  const doLike = async () => {
    if (types == 'comment' && content.commentId) {
      const body = {
        commentId: content.commentId,
        likedByUser: !isLike,
        likesCount: isLike ? likesCount - 1 : likesCount + 1,
      }
      if (isLike) {
        const res = await CommentService.UpdateComment('commentId', body)
        if (!res) {
          setIsLike(false)
          setLikesCount(likesCount - 1)
        }
      } else {
        // likes_count 조정
        // like 테이블에 넣기
        const res = await CommentService.UpdateComment('commentId', body)
        if (!res) {
          setIsLike(true)
          setLikesCount(likesCount + 1)
        }
      }
    } else if (types == 'post') {
    }
  }

  return (
    <div className='flex flex-row z-10'>
      {types == 'comment' && (
        <div
          onClick={doLike}
          className='hover:bg-slate-100 rounded-[4px] sm:p-1 cursor-pointer flex flex-row items-center justify-center mr-2 transition duration-200 ease-in-out transform active:scale-[90%]'
        >
          <Heart
            className='w-4 h-4'
            color={isLike ? 'red' : '#717171'}
            fill={isLike ? 'red' : 'white'}
          />
          {/* <div className='text-[#515151] ml-[2px]'>{likesCount}</div> */}
        </div>
      )}
      <div className='flex flex-row items-center justify-center pr-2'>
        <MessageCircle className='w-4 h-4' color='#717171' />
        <div className='text-[#717171] ml-[2px]'>
          {content.comments ? content.comments.length : 0}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ContentBottomBar)
