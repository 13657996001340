import { useUserStore } from '../../store/useUserStore'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import ProfileIcon from '../atom/ProfileIcon'
import TextareaAutosize from 'react-textarea-autosize'
import { UserService } from '../../services/user-service'
import { supabase } from '../../services/supabase'

type BottomSheetProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const checkValid = (profileName: string): string => {
  if (!profileName) return 'Name cannot be empty'
  if (profileName.length < 3)
    return 'Your username should be at least 3 characters long'
  if (profileName.includes(' ')) return 'Your username cannot contain space'
  return ''
}

const EditProfile = ({ open, setOpen }: BottomSheetProps) => {
  const { name, setName, setBio, setImgUrl, bio, imgUrl, userId } =
    useUserStore()
  const [profileName, setProfileName] = useState<string>('')
  const [profileBio, setProfileBio] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [isUploading, setIsUploading] = useState<boolean>(false)

  useEffect(() => {
    if (open) {
      setProfileBio(bio)
      setProfileName(name)
      setErrorMsg('')
    }
  }, [open])

  const changeProfile = async () => {
    if (name == profileName && bio == profileBio) {
      setOpen(false)
      return
    }

    const error = checkValid(profileName)
    if (error) {
      setErrorMsg(error)
      return
    }

    const body = {
      userId: userId,
      name: profileName,
      bio: profileBio,
    }
    const res = await UserService.UpdateUser('userId', body)

    if (!res) {
      setBio(profileBio)
      setName(profileName)
      setOpen(false)
    }
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) return

    try {
      setIsUploading(true)
      // 파일 이름 생성 (고유한 이름을 위해 타임스탬프 사용)
      const fileName = `${Date.now()}_${file.name}`

      // Supabase Storage에 파일 업로드
      const { data, error } = await supabase.storage
        .from('user_profiles') // 'images'는 Supabase의 버킷 이름입니다. 필요에 따라 변경하세요.
        .upload(fileName, file)

      if (error) throw error
      const url =
        process.env.REACT_APP_SUPABASE_URL +
        '/storage/v1/object/public/' +
        data.fullPath

      const body = {
        userId: userId,
        profileImageUrl: url,
      }
      const res = await UserService.UpdateUser('userId', body)

      if (!res) {
        setImgUrl(url)
      }
      setIsUploading(false)
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  return (
    <BottomSheet
      open={open}
      onDismiss={() => {
        setOpen(false)
      }}
      header={
        <div className='grid grid-flow-col grid-cols-6 p-2'>
          <div
            className='col-span-1 text-left'
            onClick={() => {
              if (!isUploading) setOpen(false)
            }}
          >
            Cancel
          </div>
          <div className='col-span-4 font-medium'>Edit Profile</div>
          <div
            onClick={() => {
              if (!isUploading) changeProfile()
            }}
            className='col-span-1 font-medium text-right text-[#148EE0] cursor-pointer'
          >
            {isUploading ? 'Uploading' : 'Done'}
          </div>
        </div>
      }
    >
      <div className='p-3 h-[86vh] bg-gray-100/60 flex flex-col justify-center items-center overflow-hidden pb-32'>
        <div className='py-3 px-4 border rounded-[16px] bg-white w-full flex flex-col'>
          <div className='flex flex-row'>
            <div className='w-[76%]'>
              <label className='font-medium text-[14px]'>Name</label>
              <input
                onFocus={() => {
                  if (errorMsg) setErrorMsg('')
                }}
                autoFocus={false}
                className='text-[15px] pt-1 pb-2 rounded-none border-b w-full border-b-gray-200 focus:border-b-black focus:outline-none transition duration-300 ease-in-out'
                placeholder='+ Write name'
                value={profileName}
                maxLength={25}
                onChange={(e) => {
                  setProfileName(e.currentTarget.value)
                }}
              />
            </div>
            <div className='w-[20%] ml-[3%] flex justify-center items-center'>
              <input
                type='file'
                accept='image/*'
                onChange={handleFileUpload}
                id='image-upload'
                className='hidden'
              />
              <label
                htmlFor='image-upload'
                className='object-cover aspect-square rounded-full'
              >
                <img
                  src={imgUrl.length > 5 ? imgUrl : '/img/profile.png'}
                  className={`aspect-square rounded-full content-center object-cover shadow-lg`}
                />
              </label>
            </div>
          </div>
          <div className='w-full mt-4'>
            <label className='font-medium text-[14px]'>Bio</label>
            <TextareaAutosize
              onFocus={() => {
                if (errorMsg) setErrorMsg('')
              }}
              className='resize-none text-[15px] pt-1 pb-2 rounded-none border-b w-full border-b-gray-200 focus:border-b-black focus:outline-none transition duration-300 ease-in-out'
              placeholder='+ Write bio'
              value={profileBio}
              onChange={(e) => {
                setProfileBio(e.currentTarget.value)
              }}
              maxRows={5}
              maxLength={120}
            />
          </div>
          <p className='text-gray-400 text-[15px]'>
            Please write anything about you. This will give your friends a
            better understanding of who you are.
          </p>
          <br />
        </div>
        {errorMsg && (
          <div className='mt-2 text-center w-full text-[14px] text-[#ff0000]'>
            {errorMsg}
          </div>
        )}
      </div>
    </BottomSheet>
  )
}

export default React.memo(EditProfile)
