import React, { useEffect, useState } from 'react'
import SourceContent from '../morecules/SourceContent'
import CommentDisplay from '../organisms/CommentDisplay'
import { PersonaCommentsProsp } from './PersonaComments'
import { useNavigate } from 'react-router-dom'
import { useReplies } from '../../hooks/replies'
import { useInView } from 'react-intersection-observer'
import PostSkeletons from '../morecules/PostSkeletons'

const PersonaReplies = ({ personaId, userId }: PersonaCommentsProsp) => {
  const navigate = useNavigate()

  const {
    replies,
    hasNextPage,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  } = useReplies(
    personaId + 'replies',
    ['userId', 'ownerId'],
    [personaId, userId]
  )

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <div>
      {replies &&
        replies.map((cmt) => {
          return (
            <div
              key={cmt.commentId}
              className='pt-1 pb-3 border-b border-gray-200'
              onClick={() => {
                navigate(`/post/${cmt.postId}`)
              }}
            >
              <CommentDisplay comment={cmt} isLine={false} />
              {cmt.comment && cmt.comment.length > 0 && (
                <SourceContent
                  postId={cmt.postId}
                  content={cmt.comment[0].content}
                />
              )}
            </div>
          )
        })}
      <>
        {!isLoading && replies.length == 0 && (
          <div className='sub'>No replies yet</div>
        )}
        {isLoading || isFetchingNextPage ? (
          <>
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
          </>
        ) : (
          <>
            <br />
            {hasNextPage ? (
              <div className='h-10' ref={ref} />
            ) : (
              <div className='sub'></div>
            )}
          </>
        )}
      </>
    </div>
  )
}
export default React.memo(PersonaReplies)
