import { PostDisplayType } from '../../services/post-service/types'
import React, { useEffect } from 'react'
import { CommentDisplayType } from '../../services/comment-service/types'
import ContentBottomBar from '../morecules/ContentBottomBar'
import ContentNameBar from '../morecules/ContentNameBar'
import { Link, useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/useUserStore'
import { CommentService } from '../../services/comment-service'

type ContentProps = {
  content: Partial<PostDisplayType> & Partial<CommentDisplayType>
  isLine: boolean | undefined
  isMain?: boolean
  types: 'post' | 'comment'
  isShort?: boolean
}

const ContentDisplay = ({
  types,
  content,
  isLine,
  isMain = false,
  isShort = false,
}: ContentProps) => {
  const navigate = useNavigate()
  const { userId } = useUserStore()

 
  useEffect(() => {
    if (!content.isRead && types == 'comment') {
      const res = CommentService.UpdateComment('commentId', {
        commentId: content.commentId,
        isRead: true,
      })
    }
  }, [])

  if (!isMain) {

    //console.log("딜리트버튼없애기위한: ")
    //console.log(content)
    
    //console.log("contentcreatedbY:")
    //console.log(content.createdBy)

    return (
      <div className='flex flex-row px-3 pt-1 text-[15px]'>
        <div className='flex flex-col justify-start items-center w-[12%] pt-1 max-w-[30px]'>
          <img
            onClick={() => {
              if (content.userId != userId)
                navigate(`/profile/${content.userId}`)
              else navigate(`/profile`)
            }}
            src={
              content.user?.profileImageUrl
                ? content.user?.profileImageUrl
                : '/svg/profile.svg'
            }
            className='w-full h-auto aspect-square rounded-full object-cover border border-gray-200 cursor-pointer'
          />
          {isLine && (
            <div className='border-[0.9px] border-slate-200 h-full mt-1'></div>
          )}
        </div>
        <div className='w-[90%] pl-2 pb-3'>
          <ContentNameBar
            contentType={types}
            name={content.user?.name}
            id={types == 'post' ? content.postId : content.commentId}
            createdAt={content.createdAt}
            contentCreatedBy={content.createdBy}
          />
          <div
            className={`${isShort ? 'line-clamp-3 mb-2' : 'pb-2'} pt-0 whitespace-pre-wrap`}
          >
            {content.content}
          </div>
          {content.imageUrl && (
            <div className='relative max-w-[100%] py-2'>
              <img
                src={content.imageUrl}
                className='max-h-[260px] rounded-[12px] border'
              />
            </div>
          )}
          <ContentBottomBar content={content} types={types} />
        </div>
      </div>
    )
  } else {
    return (
      <div className='px-4 pt-0 text-[15px]'>
        <div className='flex flex-row items-center content-center'>
          <div className='flex flex-col justify-start items-center w-[12%] pt-1 max-w-[30px]'>
            <img
              src={
                content.user?.profileImageUrl
                  ? content.user?.profileImageUrl
                  : '/svg/profile.svg'
              }
              className='w-full h-auto aspect-square rounded-full object-cover border border-gray-400'
            />
            {isLine && (
              <div className='border-[0.9px] border-slate-200 h-full mt-1'></div>
            )}
          </div>
          <div className='w-[90%] pl-2'>
            <ContentNameBar
              contentType={types}
              name={content.user?.name}
              id={types == 'post' ? content.postId : content.commentId}
              createdAt={content.createdAt}
              contentCreatedBy={content.createdBy}
            />
          </div>
        </div>
        <div className='pb-2 pt-2 whitespace-pre-wrap'>{content.content}</div>
        {content.imageUrl && (
          <div className='relative max-w-[100%] py-2'>
            <img
              src={content.imageUrl}
              className='max-h-[260px] rounded-[12px] border'
            />
          </div>
        )}
        <ContentBottomBar content={content} types={types} />
      </div>
    )
  }
}

export default React.memo(ContentDisplay)
