import React, { useCallback, useMemo, useState } from 'react'
import { ContentProps } from './ContentBottomBar'
import { timeAgo } from '../../utils/formatDate'
import CustomBottomSheet from '../organisms/BottomSheet'
import { Ellipsis } from 'lucide-react'

type ContentNameBarProps = {
  name?: string
  createdAt?: string
  id?: string
  contentType: 'post' | 'comment'
  contentCreatedBy?: string
}

const ContentNameBar = ({
  name,
  createdAt,
  id,
  contentType,
  contentCreatedBy
}: ContentNameBarProps) => {
  const [open, setOpen] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)

  const ago = useMemo(() => {
    if (createdAt) return timeAgo(createdAt)
  }, [createdAt])

  const onClick = useCallback(() => {
    setIsDeleted(true)
  }, [])

  return (
    <div
      className={`flex flex-row justify-between items-center relative ${isDeleted && 'bg-red-50/90 rounded-[4px]'}`}
    >
      <div className='flex flex-row'>
        <div className='font-semibold'>{name}</div>
        <div className='text-slate-400 ml-2'>{ago}</div>
        {isDeleted && (
          <div className='text-red-400 ml-2 text-[14px] font-light'>
            deleted
          </div>
        )}
      </div>
      <div
        className='cursor-pointer hover:bg-slate-50 py-1 px-2 rounded-[4px]'
        onClick={() => setOpen(true)}
      >
        <Ellipsis className='w-[18px] rounded-full text-gray-400' />
      </div>
      {id && (
        <CustomBottomSheet
          open={open}
          setOpen={setOpen}
          contentId={id}
          contentType={contentType}
          onClick={onClick}
          contentCreatedBy={contentCreatedBy}
        />
      )}
    </div>
  )
}

export default React.memo(ContentNameBar)
