import { PERSONAS } from '../utils/prompts'
import OpenAI from 'openai'

export const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_KEY,
  dangerouslyAllowBrowser: true,
})

export const chatComplete = async (
  personaId: string,
  content: string, //content는 유저가 새로 단 내용이다. 
  convs?: any[],
  url?: string,
  bio?: string
) => {
  const conversations = () => {
    if (convs) return convs
    else return []
  }

  //console.log("conversations함수의결과:")
  //console.log(conversations())

  const SystemPrompt = PERSONAS.find((doc) => doc.id == personaId)?.system
  let prompt = ''
  if (convs && convs.length > 0) {
    prompt = content //만약에 이전에 한 대화가 있으면 프롬프트는 그냥 유저가 이번에 새로 쓴 말이다. 
  } else { //만약에 이전에 한 대화가 없다면 (이런 경우가 있을 수 있나..?)
    if (bio)
      prompt =
        `
          This is additional information. But do not refer to my bio unless if it is necessary to answer my post..
          My bio : ${bio}
        ` +
        PERSONAS.find((doc) => doc.id == personaId)?.user +
        'new post I wrote : ' +
        content
    else
      prompt =
        PERSONAS.find((doc) => doc.id == personaId)?.user +
        'new post I wrote : ' +
        content
  }

  const inputPrompt: any[] = [{ type: 'text', text: prompt }]
  if (url) {
    inputPrompt.push({
      type: 'image_url',
      image_url: {
        url: url,
      },
    })
  }

  const model = process.env.NODE_ENV == 'development' ? 'gpt-4o-mini' : 'gpt-4o'

  //console.log("agent function 제대로 되는지 체크")
  //console.log(SystemPrompt)
  //console.log(conversations())
  //console.log(inputPrompt)


  const completion = await openai.chat.completions.create({
    messages: [
      {
        role: 'system',
        content: SystemPrompt,
      },
      ...conversations(),
      {
        role: 'user',
        content: inputPrompt,
      },
    ],
    temperature: 0.8,
    model: model,
  })

  
  return completion.choices[0].message.content
}
