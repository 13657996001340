import React from 'react'
import { useNavigate } from 'react-router-dom'

type PageTopBarProps = {
  text: React.ReactNode | string
}

const PageTopBar = ({ text }: PageTopBarProps) => {
  const navigate = useNavigate()

  return (
    <div className='pb-[50px]'>
      <div className='grid gap-2 z-20 grid-flex-row grid-cols-6 bg-white border-b border-b-gray-100 p-3 h-[50px] py-4 justify-between content-center fixed top-0 w-full max-w-[600px]'>
        <div
          className='col-span-1 text-[14px] flex flex-row items-center justify-start cursor-pointer'
          onClick={() => {
            navigate(-1)
          }}
        >
          <img src='/svg/left.svg' className='w-5' />
          <div className='content-center flex justify-center text-[16px] text-gray-700 hover:text-black'>
            Back
          </div>
        </div>
        <div className='col-span-4 text-center font-semibold text-[16px] content-center flex flex-col items-center justify-center'>
          {text}
        </div>
        <div className='col-span-1 justify-end content-end flex'></div>
      </div>
    </div>
  )
}

export default React.memo(PageTopBar)
