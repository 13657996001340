import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../store/useUserStore'
import { ChevronLeft, ListFilter } from 'lucide-react'
import ProfileTop from '../components/organisms/ProfileTop'
import { UserInfoType } from './PersonaProfilePage'
import MenuOptions from '../components/morecules/MenuOptions'
import ProfilePosts from '../components/organisms/profile/ProfilePosts'
import ProfileReplies from '../components/organisms/profile/ProfileReplies'
import EditProfile from '../components/organisms/EditProfile'
import ProfileLikes from '../components/organisms/profile/ProfileLikes'
import { useQuery } from 'react-query'
import { supabase } from '../services/supabase'
import { PERSONAS } from '../utils/prompts'

enum Categories {
  POSTS = 'Posts',
  REPLIES = 'Replies',
  LIKED = 'Liked',
}

const ProfilePage = () => {
  const [category, setCategory] = useState<Categories>(Categories.POSTS)
  const [isOpenEditProfile, setIsOpenEditProfile] = useState<boolean>(false)
  const navigate = useNavigate()
  const { userId, name, imgUrl, bio } = useUserStore()

  const userInfo = useMemo((): UserInfoType => {
    return {
      bio: bio,
      name: name,
      profileImageUrl: imgUrl,
    }
  }, [userId, bio, name, imgUrl])

  const fetchDetails = useCallback(async () => {
    const { count: postCount } = await supabase
      .from('post')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId)
      .filter('status', 'is', null)

    const { count: replyCount } = await supabase
      .from('comment')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('owner_id', userId)
      .filter('status', 'is', null)

    console.log('호출 확인 : ', postCount, replyCount)

    return {
      postCount: postCount,
      replyCount: replyCount,
    }
  }, [userId])

  const { data: count, isLoading, isError } = useQuery('usages', fetchDetails)

  return (
    <div className='max-h-[100vh]'>
      <div className='px-4 pb-2 pt-4 flex flex-row justify-between'>
        <div>
          <button
            onClick={() => {
              navigate(-1)
            }}
          >
            <ChevronLeft />
          </button>
        </div>
        <div>
          <button onClick={() => navigate('/setting')}>
            <ListFilter size='22' className='mt-[-2px]' />
          </button>
        </div>
      </div>
      <ProfileTop userInfo={userInfo} />
      <div className='px-4'>
        <div className='mt-4 text-gray-400 text-[0.9em]'>
          <div>
            {count?.postCount} posts · {count?.replyCount} replies ·{' '}
            {PERSONAS.length} friends
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              setIsOpenEditProfile(true)
            }}
            className={`mt-4 border border-gray-300 rounded-[8px] w-full p-1 font-medium bg-white text-black`}
          >
            Edit Profile
          </button>
        </div>
      </div>
      <div className='mt-4 relative w-full'>
        <div
          style={{
            transition: '0.3s ease',
            left:
              category == Categories.POSTS
                ? '5%'
                : category == Categories.REPLIES
                  ? '35%'
                  : '65%',
          }}
          className={`border-black border-b-[1px] absolute bottom-0 w-[30vw] max-w-[180px]`}
        ></div>
        <MenuOptions
          category={category}
          setCategory={(cat) => setCategory(cat as Categories)}
          Categories={Object.values(Categories)}
        />
      </div>
      <div className='pt-1 relative pb-12'>
        {category == Categories.POSTS && <ProfilePosts />}
        {category == Categories.REPLIES && <ProfileReplies />}
        {category == Categories.LIKED && <ProfileLikes />}
      </div>
      <EditProfile open={isOpenEditProfile} setOpen={setIsOpenEditProfile} />
    </div>
  )
}

export default React.memo(ProfilePage)
