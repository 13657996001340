import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PostService } from '../services/post-service'
import ContentDisplay from '../components/organisms/ContentDisplay'
import { useUserStore } from '../store/useUserStore'
import CommentDisplay from '../components/organisms/CommentDisplay'
import { useReplyStore } from '../store/useReplyStore'
import { CommentDisplayType } from '../services/comment-service/types'
import { usePostStore } from '../store/usePostStore'
import { useQuery, useQueryClient } from 'react-query'
import PageTopBar from '../components/morecules/PageTopBar'
import withAnimation from '../components/templates/withAnimation'
import { isMobile } from 'react-device-detect';

export const returnNestedComments = (
  replies: CommentDisplayType[],
  parentCommentId: string
) => {
  const comment = replies.find(
    (reply) => reply.parentCommentId == parentCommentId
  )

  if (comment) {
    const findNested = returnNestedComments(replies, comment.commentId)
    if (findNested) {
      comment.comments = findNested
    }
    return [comment]
  } else {
    return false
  }
}

const DetailPage = () => {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const { userId, imgUrl } = useUserStore()
  const { setContent } = usePostStore()
  const { setParent } = useReplyStore()

  const fetchDetails = async () => {
    const res = await getPost()
    if (res) setContent(res)
    return res
  }

  const {
    data: content,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(id, fetchDetails, {
    staleTime: 5000,
    refetchOnWindowFocus: false,
  })

  const getPost = async () => {
    const res = await PostService.GetPost(userId, 'postId', id)
    if (res[0].status == 'deleted') {
      navigate('/')
      return
    }

    if (res && res.length > 0) {
      let totalComments: CommentDisplayType[] = []
      if (res[0].comments) {
        let replies = res[0].comments.filter((doc) => doc.parentCommentId)
        if (replies && replies.length > 0) {
          res[0].comments
            .filter((doc) => !doc.parentCommentId)
            .forEach((doc) => {
              const findNested = returnNestedComments(replies, doc.commentId)
              if (findNested) {
                const temporal = {
                  ...doc,
                  comments: findNested as CommentDisplayType[],
                }
                totalComments.push(temporal)
              } else {
                totalComments.push(doc)
              }
            })
        } else {
          totalComments = res[0].comments
        }
      }
      return {
        ...res[0],
        comments: totalComments,
      }
    }
  }

  useEffect(() => {
    getPost()
  }, [id])

  const reply = (comment: CommentDisplayType) => {
    if (!content) return

    const conversation: {
      commenterType: 'user' | 'assistant'
      content: string
      imageUrl?: string | null
    }[] = [
      {
        commenterType: (content.createdBy == 'user' || content.createdBy == null) ? 'user' : 'assistant',
        content: content.content,
        imageUrl: content.imageUrl,
      },
    ]

    let cmts = [comment]
    let lastComment: CommentDisplayType = { ...comment }
    let c = 0
    while (cmts && c < 30) {
      let cmt = cmts.pop()
      if (!cmt) {
        break
      }
      lastComment = { ...cmt }

      if (cmt.comments) {
        cmts.push(cmt.comments[0])
      }
      conversation.push({
        commenterType: cmt.createdBy == 'user' ? 'user' : 'assistant',
        content: cmt.content,
      })
      c += 1
    }

    setParent({
      profileUrl: lastComment.user ? lastComment.user.profileImageUrl : '',
      name: lastComment.user ? lastComment.user.name : '',
      content: lastComment.content,
      createdAt: lastComment.createdAt,
      postId: lastComment.postId,
      userId: lastComment.userId,
      conversation: conversation,
      typed: 'reply',
    })
    navigate(`/write/${lastComment?.commentId}`)
  }

  return (
    <div>
      {content && !isLoading && (
        <>
          <PageTopBar
            text={
              <>
                <div>Post</div>
                {content.comments && (
                  <>
                    <div className='text-gray-400 mt-[-4px] font-normal text-[13px]'>
                      {content.comments.length} comments
                    </div>
                  </>
                )}
              </>
            }
          />
          <div className='pt-4'>
            <ContentDisplay
              types='post'
              content={content}
              isLine={false}
              isMain={true}
            />
          </div>
          <div className='px-4 py-2 mt-4 mb-0 border-y border-gray-200/60'>
            <div className='font-semibold text-[14px]'>Comments</div>
          </div>
          <div>
            {content.comments && (
              <>
                {content.comments.map((comment) => {
                  return (
                    <div
                      key={comment.commentId}
                      className='border-b border-gray-200/60 pt-2 pb-1'
                    >
                      <CommentDisplay
                        comment={comment}
                        isLine={
                          comment.comments && comment.comments.length > 0
                            ? true
                            : false
                        }
                      />
                      <div
                        className='pl-12 pb-2 text-[15px] mt-[-8px] text-gray-400 hover:text-gray-700 cursor-pointer transition duration-200 ease'
                        onClick={() => {
                          reply(comment)
                        }}
                      >
                        Reply
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          </div>
          
            <div
        className={`
          bg-gray-100 p-2 rounded-full flex flex-row items-center cursor-pointer
          hover:bg-gray-100/70 fixed z-50
          ${isMobile ? 'w-[95%] left-1/2 transform -translate-x-1/2 bottom-20' : 'w-[600px] left-1/2 transform -translate-x-1/2 bottom-2'}
        `}
        style={{ padding: '10px', margin: '0 auto', position: 'fixed' }}
        onClick={() => {

          if(content.createdBy == 'ai'){

            const conversation: {
              commenterType: 'user' | 'assistant'
              content: string
              imageUrl?: string | null
            }[] = [
              {
                commenterType:  'assistant',
                content: content.content,
                imageUrl: content.imageUrl,
              },
            ]

            setParent({
              profileUrl: content.user.profileImageUrl,
              name: content.user.name,
              content: content.content,
              createdAt: content.createdAt,
              postId: content.postId,
              userId: content.userId,
              conversation: conversation,
              typed: 'comment',
            })
          } else {
            setParent({
              profileUrl: content.user.profileImageUrl,
              name: content.user.name,
              content: content.content,
              createdAt: content.createdAt,
              postId: content.postId,
              userId: content.userId,
              typed: 'comment',
            })
          }


          navigate(`/write/${content?.postId}`)
        }}
      >
        <img
          src={imgUrl ? imgUrl : '/svg/profile.svg'}
          className='w-7 h-7 rounded-full'
          alt='Profile'
        />
        <p className='text-gray-400 ml-2 text-[14px] font-[500]'>
          Add comment to this Post...
        </p>
      </div>
    

          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </div>
  )
}

export default withAnimation(React.memo(DetailPage))
