import React, { useState } from 'react'
import MainPostList from '../components/templates/MainPostList'
import { usePosts } from '../hooks/posts'
import { useUserStore } from '../store/useUserStore'
import PostSkeletons from '../components/morecules/PostSkeletons'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'
import {
  Ban,
  Cat,
  ListFilter,
  MessageCircle,
  Pencil,
  MessagesSquare,
  Sticker,
} from 'lucide-react'
import FeedbackModal from '../components/morecules/FeedbackModal'
import { isMobile } from 'react-device-detect'
import OnBoarding from '../components/organisms/OnBoarding'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/morecules/Footer'

const HomePage = () => {
  // const [posts, setPosts] = useState<PostDisplayType[]>([])
  const [isOnBoarding, setIsOnBoarding] = useState<boolean>(false)
  const { userId, bio } = useUserStore()
  const {
    hasNextPage,
    posts,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  } = usePosts('userId', userId)
  const navigate = useNavigate()

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView])

  useEffect(() => {
    if (!isLoading && posts.length == 0) {
      const didOnboarding = localStorage.getItem('isDidOnboarding')
      if (didOnboarding || bio) {
        return
      } else {
        setIsOnBoarding(true)
        localStorage.setItem('isDidOnboarding', 'true')
      }
    }
  }, [isLoading])

  return (
    <div className='min-h-[90vh]'>
      <div
        className={`grid grid-row grid-cols-6 justify-center p-4 content-center text-center h-[50px] sm:justify-between sm:w-[100vw] sm:left-0 sm:fixed sm:bg-slate-100/0`}
      >
        {isMobile && <div className='col-span-1'></div>}
        <p className='font-semibold text-lg col-span-4 sm:col-span-5 sm:text-left'>
          Melonn
        </p>
        <div className='col-span-1 flex justify-end items-center'>
          <FeedbackModal desc='Thank you for helping us! We want to hear from you. How can we improve Melonn?'>
            {!isMobile ? (
              <div className='flex flex-row items-center'>
                <Sticker color='#515151' className='w-5' />
                <div className='ml-[6px]'>Feedback</div>
              </div>
            ) : (
              <Sticker color='#515151' className='w-5' />
            )}
          </FeedbackModal>
          <div
          className='cursor-pointer flex flex-row items-center ml-2'
          onClick={() => {
            window.open('https://discord.gg/BEQRz4vqPj', '_blank')
          }}
        >
          {!isMobile ? (
              <div className='flex flex-row items-center'>
                <MessagesSquare color='#515151' className='w-5' />
                <div className='ml-[6px]'>Discord </div>
              </div>
            ) : (
              <MessagesSquare color='#515151' className='w-5' />
            )}
        </div>
          <div
            className='cursor-pointer'
            onClick={() => {
              navigate('/setting')
            }}
          >
            <ListFilter className='w-5 ml-2' />
          </div>
        </div>
      </div>
      {!isMobile && (
        <>
          <br />
          {posts.length == 1 && (
            <>
              <div className='h-[2vh]' />
            </>
          )}
        </>
      )}

      {!isLoading && !isOnBoarding && <MainPostList posts={posts} />}
      {isLoading || isFetchingNextPage ? (
        <>
          <PostSkeletons />
          <PostSkeletons />
          <PostSkeletons />
          <PostSkeletons />
        </>
      ) : (
        <>
          <br />
          {hasNextPage ? (
            <div className='h-10' ref={ref} />
          ) : (
            <div className='sub py-4'></div>
          )}
        </>
      )}
      {isOnBoarding && <OnBoarding onOpen={setIsOnBoarding} />}
      {!isLoading && posts.length < 1 && (
        <div className='w-full text-center min-h-[50vh] flex items-center justify-center'>
          <div className='w-[100%] flex flex-col justify-center items-center sm:mt-12 mb-8'>
            {posts.length == 0 && (
              <p className='text-gray-700 mb-2 text-[24px] font-medium'>
                Your safespace to express
                <br />
                anything on your mind
              </p>
            )}
            <div className='grid grid-flow-row gap-8 mt-8 py-8 px-4 sm:px-6 rounded-[12px] bg-gray-100/50 w-[90%] sm:w-[96%]'>
              <Listed
                text={
                  <>
                    <div className='font-semibold'>
                      Press '+' at the tab bar to start writing
                    </div>
                    anything's okay. how about-”does a straw have one hole, or
                    two?”
                  </>
                }
                icon={<Pencil className='w-[16px] text-[rgb(145, 42, 42)]' />}
              />
              <Listed
                text={
                  <>
                    <div className='font-semibold'>
                      Wait for AI friends’ comments
                    </div>
                    various AI friends will respond to your post based on their
                    personalities
                  </>
                }
                icon={<Cat className='w-[16px]' />}
              />
              <Listed
                text={
                  <>
                    <div className='font-semibold'>
                      Continue the conversation with reply
                    </div>
                    if you something you want to say about the comment, leave a
                    reply
                  </>
                }
                icon={
                  <MessageCircle className='w-[16px] text-[rgb(0,0,180)]' />
                }
              />
              <Listed
                text={
                  <>
                    <div className='font-semibold'>
                      Block if you didn’t like the comment
                    </div>
                    if you didn’t like comments from a certain AI friend, you
                    can block him/her
                  </>
                }
                icon={<Ban className='w-[16px] text-[red]/50' />}
              />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default React.memo(HomePage)

const Listed = ({
  text,
  icon,
}: {
  text: React.ReactNode
  icon: React.ReactNode
}) => {
  return (
    <div className='flex flex-row items-start cursor-pointer text-gray-700 hover:text-gray-900 italic'>
      <div className='text-[10px] flex justify-center items-start box w-6 h-6 rounded-[4px] mr-2 mt-[6px] bg-gray-200'>
        {icon}
      </div>
      <div className='text-left pl-3 max-w-[90%]'>{text}</div>
    </div>
  )
}
