import snakeCase from 'lodash.snakecase'
import {
  deleteData,
  insertData,
  returnSupabase,
  supabase,
  updateData,
  upsertData,
} from '../supabase'
import { BlockColumns, BlockQueryType, BlockType } from './types'

export class BlockService {
  constructor() {}
  static TABLE_NAME = 'block'

  static async InsertBlock(request_data: BlockType): Promise<any> {
    const result = await insertData(BlockService.TABLE_NAME, request_data)
    return result
  }

  static async GetBlock(
    column: BlockColumns[],
    value: string[] | number[]
  ): Promise<BlockType[]> {
    const { data, error } = await supabase
      .from(BlockService.TABLE_NAME)
      .select()
      .eq(snakeCase(column[0]), value[0])
      .eq(snakeCase(column[1]), value[1])
      .limit(1)

    return returnSupabase(data, error)
  }

  static async GetBlocks(
    column: BlockColumns[],
    value: string[] | number[]
  ): Promise<BlockType[]> {
    const { data, error } = await supabase
      .from(BlockService.TABLE_NAME)
      .select()
      .eq(snakeCase(column[0]), value[0])

    return returnSupabase(data, error)
  }

  static async DeleteBlock(body: BlockQueryType) {
    const result = await deleteData(BlockService.TABLE_NAME, body)

    return result
  }
}
