import React from 'react'

type TitleProps = {
  text: string
}

const Title = ({ text }: TitleProps) => {
  return <div className='font-semibold text-[2em]'>{text}</div>
}

export default React.memo(Title)
