import React, { Dispatch, SetStateAction } from 'react'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
} from '../atom/ui/alert-dialog'

type AlertProps = {
  title: string
  desc: React.ReactNode
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClick: () => void
}

const CustomAlert = ({ title, desc, open, setOpen, onClick }: AlertProps) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className='bg-white/100 w-[80%] max-w-[400px] rounded-[20px] sm:rounded-[20px] md:rounded-[20px] overflow-hidden pb-20 border-none'>
        <AlertDialogHeader>
          <AlertDialogTitle className='text-[18px]'>
            {title}
            {/* Are you absolutely sure? */}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {desc}
            {/* This action cannot be undone. This will permanently delete your
            account and remove your data from our servers. */}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className='absolute bottom-0 w-full flex flex-row border-t border-gray-200 gap-0'>
          <button
            onClick={() => {
              setOpen(false)
            }}
            className='w-full p-3 text-[16px] text-black hover:bg-gray-100'
          >
            Cancel
          </button>
          <div className='border-r border-gray-200 m-0'></div>
          <button
            onClick={() => onClick()}
            className='w-full p-3 font-medium text-[16px] text-[#ff1111] hover:bg-gray-100'
          >
            Continue
          </button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default React.memo(CustomAlert)
