import { CommentService } from '../../services/comment-service'
import React, { Dispatch, SetStateAction } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import CustomAlert from './CustomAlert'
import { PostService } from '../../services/post-service'
import Snackbar from '../morecules/SnackBar'
import { SnackBarTypes } from '../morecules/SnackBarUI'
import TextareaAutosize from 'react-textarea-autosize'
import { supabase } from '../../services/supabase'
import { useUserStore } from '../../store/useUserStore'
import { mainPostQueryKey } from '../../hooks/posts'
import { useQueryClient } from 'react-query'

type BottomSheetProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  contentId: string
  contentType: 'post' | 'comment'
  contentCreatedBy?: string 
  onClick: () => void
}

const CustomBottomSheet = ({
  open,
  setOpen,
  contentId,
  contentType,
  contentCreatedBy,
  onClick,
}: BottomSheetProps) => {
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [reportOpen, setReportOpen] = React.useState(false)
  const [reportText, setReportText] = React.useState('')
  const { userId, name } = useUserStore()
  const queryClient = useQueryClient()

  const deleteContent = async () => {
    if (contentType == 'comment') {
      const body = {
        commentId: contentId,
        status: 'deleted',
      }
      const res = await CommentService.UpdateComment('commentId', body)
      Snackbar.show({
        text: SnackBarTypes.DELETE,
      })
    } else if (contentType == 'post') {
      const body = {
        postId: contentId,
        status: 'deleted',
      }
      const res = await PostService.UpdatePost('postId', body)
      Snackbar.show({
        text: SnackBarTypes.DELETE,
      })

      const mainPosts = queryClient.getQueryData(mainPostQueryKey) as any
      if (mainPosts && mainPosts.pages && mainPosts.pages.flat().length > 0) {
        // Home에 바로 넣기
        queryClient.setQueryData(mainPostQueryKey, {
          pages: [
            ...mainPosts.pages
              .flat()
              .filter((doc: any) => doc.postId != contentId),
          ],
          pageParams: [...mainPosts.pageParams],
        })
      }
    }
  }

  const onDelete = async () => {
    await deleteContent()
    setAlertOpen(false)
    onClick()
  }

  const onReport = async () => {
    await deleteContent()
    const body = {
      user_id: userId,
      name: name,
      content: reportText,
      type: 'REPORT',
    }
    const { data, error } = await supabase.from('feedback').insert(body)

    setReportOpen(false)
    onClick()
  }

  //console.log("contentType: ")
  //console.log(contentType)
  //console.log("contentCreatedBy: ")
  //console.log(contentCreatedBy)

  
  if(contentType == 'post' && contentCreatedBy == 'ai'){
    return(
      <>
      <BottomSheet open={open} onDismiss={() => setOpen(false)}>
        <div className='p-4 bg-gray-100/90'>
          <Row
            label='Report'
            onClick={() => {
              setOpen(false)
              setReportOpen(true)
            }}
            src='/svg/report.svg'
            scheme='warn'
          />
        </div>
      </BottomSheet>
      
      <CustomAlert
        open={reportOpen}
        setOpen={setReportOpen}
        title='Report this content'
        desc={
          <>
            <p>
              Sorry for the inconvenience. If there is any reason that makes you
              uncomfortable, please write it below.
              <br />
              <br />
              This comment will be deleted after your submission.
            </p>
            <TextareaAutosize
              className='rounded-[4px] border transition duration-300 ease focus:outline-none focus:border-black/90 mt-4 mb-1 border-gray-300 w-full p-3 resize-none text-[16px]'
              value={reportText}
              placeholder='Please describe the reason for your report (optional).'
              onChange={(e) => setReportText(e.currentTarget.value)}
            />
          </>
        }
        onClick={() => {
          onReport()
        }}
      />
    </>
    )
  }
  
  return (
    <>
      <BottomSheet open={open} onDismiss={() => setOpen(false)}>
        <div className='p-4 bg-gray-100/90'>
          <Row
            label='Delete'
            onClick={() => {
              setOpen(false)
              setAlertOpen(true)
            }}
            src='/img/delete.png'
          />
          <Row
            label='Report'
            onClick={() => {
              setOpen(false)
              setReportOpen(true)
            }}
            src='/svg/report.svg'
            scheme='warn'
          />
        </div>
      </BottomSheet>
      <CustomAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        title='Delete Post'
        desc='Once you delete the post, you won’t be able to restore it, including comments written by your friends.'
        onClick={() => {
          onDelete()
        }}
      />
      <CustomAlert
        open={reportOpen}
        setOpen={setReportOpen}
        title='Report this content'
        desc={
          <>
            <p>
              Sorry for the inconvenience. If there is any reason that makes you
              uncomfortable, please write it below.
              <br />
              <br />
              This comment will be deleted after your submission.
            </p>
            <TextareaAutosize
              className='rounded-[4px] border transition duration-300 ease focus:outline-none focus:border-black/90 mt-4 mb-1 border-gray-300 w-full p-3 resize-none text-[16px]'
              value={reportText}
              placeholder='Please describe the reason for your report (optional).'
              onChange={(e) => setReportText(e.currentTarget.value)}
            />
          </>
        }
        onClick={() => {
          onReport()
        }}
      />
    </>
  )
}

export default React.memo(CustomBottomSheet)

type RowProps = {
  label: string
  onClick: () => void
  src?: string
  scheme?: 'basic' | 'warn'
}

const Row = ({ label, onClick, src, scheme }: RowProps) => {
  return (
    <div
      onClick={() => onClick()}
      className={`cursor-pointer hover:bg-slate-50 mb-3 py-3 px-4 bg-white flex flex-row justify-between rounded-[15px] font-medium ${scheme == 'warn' ? 'text-red-500' : ''} content-center`}
    >
      <div>{label}</div>
      <div className='content-center'>
        {src && <img src={src} className='w-5' />}
      </div>
    </div>
  )
}
