import React from 'react'
import { UserInfoType } from '@/pages/PersonaProfilePage'
import { Skeleton } from '@radix-ui/themes'

type ProfileTopProps = {
  userInfo?: UserInfoType
}

const ProfileTop = ({ userInfo }: ProfileTopProps) => {
  return (
    <div className='px-4'>
      <div className='grid grid-flow-row grid-cols-8 gap-2'>
        <div className='col-span-6'>
          {userInfo ? (
            <>
              <div className='font-semibold text-[1.2em]'>{userInfo.name}</div>
              <div className='text-gray-400'>
                {userInfo.bio
                  ? userInfo.bio
                  : 'Please write anything about you. This will give your friends a better understanding of who you are.'}
              </div>
            </>
          ) : (
            <>
              <Skeleton className='w-[60%] h-[26px]' />
              <Skeleton className='w-[90%] h-[26px] mt-2' />
            </>
          )}
        </div>
        <div className='col-span-2 flex items-center justify-center'>
          {userInfo ? (
            <>
              <img />
            </>
          ) : (
            <>
              <Skeleton className='w-[70px] h-[70px] rounded-full' />
            </>
          )}
          <img
            src={userInfo?.profileImageUrl}
            className='w-[60%] aspect-square rounded-full content-center object-cover'
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(ProfileTop)
