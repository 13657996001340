import { supabase } from '../services/supabase'
import { Button } from '../components/atom/ui/button'
import React, { useEffect, useState } from 'react'

const UsagePage = () => {
  const [counts, setCounts] = useState<number[]>([])
  const [heavies, setHeavies] = useState<any[]>([])

  const getUsage = async () => {
    const { data, error } = await supabase
      .from('user')
      .select(
        `
        user_id,
        name,
        post (user_id, post_id, content)
        `
      )
      .neq('user_id', 'd93b8c04-52c9-42c3-b2a0-c1725065de9f')
      .eq('type', 1)

    console.log('데이터 값 : ', data)

    if (data) {
      // 0번
      const zero = data.filter((doc) => doc.post.length == 0).length
      // 1번
      const once = data.filter((doc) => doc.post.length == 1).length
      // 2번
      const twice = data.filter((doc) => doc.post.length == 2).length
      // 2~4번
      const twotofour = data.filter(
        (doc) => doc.post.length > 2 && doc.post.length <= 4
      ).length
      // 5~10번
      const fivetoten = data.filter(
        (doc) => doc.post.length > 4 && doc.post.length <= 10
      ).length
      // 10 ~ 20번
      const tentotwenty = data.filter(
        (doc) => doc.post.length > 10 && doc.post.length <= 20
      ).length
      // 20번 이상
      const more = data.filter((doc) => doc.post.length > 20).length

      setCounts([zero, once, twice, twotofour, fivetoten, tentotwenty, more])

      const heavyUsers = data.filter((doc) => doc.post.length > 5)
      setHeavies(heavyUsers)
    }
  }

  const getRetention = async () => {
    const oneDayAgo = new Date(Date.now() - 32 * 60 * 60 * 1000).toISOString()
    const { data, error } = await supabase
      .from('user')
      .select(
        `
        user_id,
        name,
        post (user_id, post_id, content, created_at)
        `
      )
      .neq('user_id', 'd93b8c04-52c9-42c3-b2a0-c1725065de9f')
      .eq('type', 1)
      .lt('registered_at', oneDayAgo)

    if (data) {
      const totalLen = data.length
      let count = 0
      let morethantwo = 0

      data.map((item) => {
        if (item.post.length > 1) {
          morethantwo += 1
          const cdates = item.post.map((doc) =>
            new Date(doc.created_at).getTime()
          )
          if (
            Math.max(...cdates) - Math.min(...cdates) >=
            20 * 60 * 60 * 1000
          ) {
            count += 1
          }
        }
      })

      console.log('출ㄹ력 : ', totalLen, morethantwo, count)
    }
  }

  const getDaily = async () => {}

  return (
    <div>
      <div>UsagePage</div>
      <Button
        className='bg-black px-4 text-white rounded-full'
        onClick={() => getDaily()}
      >
        Get Daily
      </Button>
      <br />
      <Button
        className='bg-black px-4 text-white rounded-full'
        onClick={() => getRetention()}
      >
        Get retention
      </Button>
      <br />
      <Button
        className='bg-black px-4 text-white rounded-full'
        onClick={() => getUsage()}
      >
        Get : 너무 많이 누르면 너무 많이 supabase 쿼리를 날린다는 뜻. 물론 의미
        없을 정도로 작긴 함
      </Button>
      {counts && (
        <>
          <div className='grid grid-flow-row gap-4 mt-10'>
            <div>0번 : {counts[0]}</div>
            <div>1번 : {counts[1]}</div>
            <div>2번 : {counts[2]}</div>
            <div>3~4번 : {counts[3]}</div>
            <div>5~10번 : {counts[4]}</div>
            <div>11~20번 : {counts[5]}</div>
            <div>20번 이상 : {counts[6]}</div>
          </div>
          <div className='grid grid-flow-row gap-4 mt-20'>
            {heavies.map((item) => {
              return (
                <div key={item.name}>
                  <div className='text-red-600'>userId : {item.user_id}</div>
                  <div>이름 : {item.name}</div>
                  <div>포스팅 수 : {item.post.length}</div>
                  <div className='grid grid-flow-row gap-1 pb-4 border-b-black'>
                    포스팅들 :{' '}
                    {item.post.map((doc: any) => {
                      return <div>- {doc.content}</div>
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default UsagePage
