import snakeCase from 'lodash.snakecase'
import {
  CommentColumns,
  CommentOnlyType,
  CommentQueryType,
  CommentType,
} from './types'
import {
  insertData,
  returnSupabase,
  supabase,
  updateData,
  upsertData,
} from '../supabase'

export const limitLength = 5

export class CommentService {
  constructor() {}
  static TABLE_NAME = 'comment'

  static async InsertComment(request_data: CommentQueryType): Promise<any> {
    const result = await insertData(CommentService.TABLE_NAME, request_data)
    return result
  }

  static async GetComment(
    column?: CommentColumns,
    value?: string | number | any,
    type?: 'none' | 'join'
  ): Promise<CommentType[]> {
    let fetched = supabase
      .from(CommentService.TABLE_NAME)
      .select()
      .order('created_at', { ascending: false })

    if (column && value) {
      fetched = fetched.eq(snakeCase(column), value)
    }
    const { data, error } = await fetched.limit(1)
    return returnSupabase(data, error)
  }

  static async GetComments(
    columns?: CommentColumns[],
    values?: any[],
    page: number = 1
  ): Promise<CommentOnlyType[]> {
    let fetched = supabase
      .from(CommentService.TABLE_NAME)
      .select(
        `
        *,
          user (user_id, name, profile_image_url),
          post (post_id, content)
        `
      )
      .order('created_at', { ascending: false })
      .is('parent_comment_id', null)
      .filter('status', 'is', null)

    if (columns && values) {
      for (let i = 0; i < columns?.length; i++) {
        const column = columns[i]
        const value = values[i]
        if (column && value) {
          fetched = fetched.eq(snakeCase(column), value)
        }
      }
    }

    const { data, error } = await fetched.range(
      (page - 1) * limitLength,
      page * limitLength - 1
    )

    return returnSupabase(data, error)
  }

  static async GetReplies(
    columns?: CommentColumns[],
    values?: any[],
    page: number = 1
  ): Promise<CommentOnlyType[]> {
    let fetched = supabase
      .from(CommentService.TABLE_NAME)
      .select(
        `
        *,
          user (user_id, name, profile_image_url),
          parent_comment_id,
          comment:comment (
            comment_id,
            content,
            created_at,
            user_id,
            user (user_id, name, profile_image_url)
          )
        `
      )
      .order('created_at', { ascending: false })
      .not('parent_comment_id', 'is', null)
      .filter('status', 'is', null)
    // .is('parent_comment_id', 'not.null')

    if (columns && values) {
      for (let i = 0; i < columns?.length; i++) {
        const column = columns[i]
        const value = values[i]
        if (column && value) {
          fetched = fetched.eq(snakeCase(column), value)
        }
      }
    }

    const { data, error } = await fetched.range(
      (page - 1) * limitLength,
      page * limitLength - 1
    )

    return returnSupabase(data, error)
  }

  static async GetLikes(
    columns?: CommentColumns[],
    values?: any[],
    page: number = 1
  ): Promise<CommentOnlyType[]> {
    let fetched = supabase
      .from(CommentService.TABLE_NAME)
      .select(
        `
        *,
          post (post_id, content),
          user (user_id, name, profile_image_url),
          parent_comment_id,
          comment:comment (*, user (user_id, name, profile_image_url)
        )
        `
      )
      .order('created_at', { ascending: false })
      .filter('status', 'is', null)

    if (columns && values) {
      for (let i = 0; i < columns?.length; i++) {
        const column = columns[i]
        const value = values[i]
        if (column && value) {
          fetched = fetched.eq(snakeCase(column), value)
        }
      }
    }

    const { data, error } = await fetched.range(
      (page - 1) * limitLength,
      page * limitLength - 1
    )

    return returnSupabase(data, error)
  }

  static async GetActivities(
    userId: string,
    page: number = 1
  ): Promise<CommentOnlyType[]> {
    let fetch = supabase
      .from(CommentService.TABLE_NAME)
      .select(
        `
        *,
          post (post_id, content),
          user (user_id, name, profile_image_url),
          parent_comment_id,
          comment:comment (*, user (user_id, name, profile_image_url)
        )
        `
      )
      .order('created_at', { ascending: false })
      .filter('status', 'is', null)
      .eq('created_by', 'ai')
      .eq('owner_id', userId)
      .eq('is_read', true)

    const { data, error } = await fetch.range(
      (page - 1) * limitLength,
      page * limitLength - 1
    )

    return returnSupabase(data, error)
  }

  static async GetUnreadActivities(userId: string): Promise<CommentOnlyType[]> {
    const { data, error } = await supabase
      .from(CommentService.TABLE_NAME)
      .select(
        `
        *,
          post (post_id, content),
          user (user_id, name, profile_image_url),
          parent_comment_id,
          comment:comment (*, user (user_id, name, profile_image_url)
        )
        `
      )
      .order('created_at', { ascending: false })
      .filter('status', 'is', null)
      .eq('created_by', 'ai')
      .eq('owner_id', userId)
      .eq('is_read', false)

    return returnSupabase(data, error)
  }

  static async GetUnReads(userId: string): Promise<CommentOnlyType[]> {
    const { data, error } = await supabase
      .from(CommentService.TABLE_NAME)
      .select(
        `
          comment_id
        `
      )
      .order('created_at', { ascending: false })
      .filter('status', 'is', null)
      .eq('created_by', 'ai')
      .eq('is_read', false)
      .eq('owner_id', userId)
      .limit(100)

    return returnSupabase(data, error)
  }

  static async UpdateComment(
    primaryKeyName: CommentColumns,
    body: CommentQueryType
  ) {
    const result = await updateData(
      CommentService.TABLE_NAME,
      primaryKeyName,
      body
    )

    return result
  }

  static async UpsertComment(
    primaryKeyName: CommentColumns,
    body: CommentQueryType
  ) {
    const result = await upsertData(
      CommentService.TABLE_NAME,
      primaryKeyName,
      body
    )

    return result
  }
}
