import { useUserStore } from '../../../store/useUserStore'
import React, { useCallback, useEffect, useState } from 'react'
import ContentDisplay from '../ContentDisplay'
import { Link, useNavigate } from 'react-router-dom'
import SourceContent from '../../morecules/SourceContent'
import { useReplies } from '../../../hooks/replies'
import { useInView } from 'react-intersection-observer'
import PostSkeletons from '../../morecules/PostSkeletons'

const ProfileReplies = () => {
  const { userId } = useUserStore()
  const navigate = useNavigate()
  const {
    replies,
    hasNextPage,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  } = useReplies('user_replies', ['userId'], [userId])

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <div>
      {!isLoading &&
        replies &&
        replies.map((cmt) => {
          return (
            <div
              onClick={() => navigate(`/post/${cmt.postId}`)}
              key={cmt.commentId}
              className='py-2 border-b border-gray-200 hover:bg-gray-50 cursor-pointer'
            >
              <ContentDisplay types='comment' content={cmt} isLine={false} />
              {cmt.comment && cmt.comment.length > 0 && (
                <SourceContent
                  postId={cmt.postId}
                  content={cmt.comment[0].content}
                  imgSrc={cmt.comment[0].user?.profileImageUrl}
                />
              )}
            </div>
          )
        })}
      <>
        {!isLoading && replies.length == 0 && (
          <div className='sub'>No replies yet</div>
        )}
        {isLoading || isFetchingNextPage ? (
          <>
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
          </>
        ) : (
          <>
            <br />
            {hasNextPage ? (
              <div className='h-10' ref={ref} />
            ) : (
              <div className='sub'></div>
            )}
          </>
        )}
      </>
    </div>
  )
}

export default React.memo(ProfileReplies)
