import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Heart, House, Plus, Search, User } from 'lucide-react'
import { supabase } from '../../services/supabase'
import { useUserStore } from '../../store/useUserStore'
import { CommentService } from '../../services/comment-service'
import { isMobile } from 'react-device-detect'
import { FunnelService } from '../../services/log-service'

const BottomBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const firstParam = location.pathname.split('/')[1]
  const { userId } = useUserStore()
  const [unReadNums, setUnReadNums] = useState<number>(0)

  // 주기적으로 불러온다 => activities안에 저장해둔다.useEffect(() => {
  useEffect(() => {
    const channelA = supabase
      .channel('public:comment')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'comment',
          filter: `owner_id=eq.${userId}`,
        },
        (payload) => {
          if (payload.new.user_id != userId) {
            setUnReadNums((prev) => prev + 1)
          }
        }
      )
      .subscribe()

    return () => {
      channelA.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (unReadNums > 0) sessionStorage.setItem('unReadNums', `${unReadNums}`)
  }, [unReadNums])

  useEffect(() => {
    ;(async () => {
      // if (unReadNums == 0 && savedUnreadNums == 0) {
      if (unReadNums == 0) {
        const res = await CommentService.GetUnReads(userId)
        if (res && res.length > 0) {
          setUnReadNums(res.length)
        }
      }
      // const res = await CommentService.GetUnReads(userId)
      // console.log('결과 보기 : ', res)
      // if (res && res.length > 0) {
      //   setUnReadNums(res.length)
      // }
    })()
  }, [])

  return (
    <div
      className={`fixed grid gap-6 px-4 py-[8px] bg-gray-50 border-gray-200 
        ${
          !isMobile
            ? 'grid-flow-col grid-rows-5 sm:px-3 max-h-[600px] top-[calc(50vh-300px)] left-0 border rounded-r-[32px] sm:pr-6 sm:pl-4 sm:gap-10 sm:py-8'
            : 'sm:gap-16 sm:px-12 w-[100%] grid-flow-row grid-cols-5 bottom-0 pb-6 max-w-[600px] border-t'
        }
        `}
    >
      <IconButton path='/'>
        <House
          className={`${firstParam == '' ? 'text-[#111111]' : 'text-[#919191]'}`}
        />
      </IconButton>
      <IconButton path='/find'>
        <Search
          className={`${firstParam == 'find' ? 'text-[#111111]' : 'text-[#919191]'}`}
        />
      </IconButton>
      <IconButton path='/write'>
        <div
          className='rounded-[8px] py-[6px] px-3 bg-[#efefef]'
          onClick={() => {
            const checkLogId = sessionStorage.getItem('logId')
            if (checkLogId) {
              const body = {
                logId: checkLogId,
                isClickWrite: true,
                userId: userId,
              }
              FunnelService.Update('logId', body)
            }
          }}
        >
          <Plus className='w-6' color='#919191' />
        </div>
      </IconButton>
      <IconButton path='/act'>
        <div className='relative p-2'>
          <Heart
            // color={firstParam == 'act' ? '#212121' : '#919191'}
            className={`${firstParam == 'act' ? 'text-[#111111]' : 'text-[#919191]'}`}
          />
          {unReadNums > 0 && (
            <div className='absolute top-[2px] right-0 h-4 px-[2px] min-w-4 bg-red-500 text-white text-center rounded-full text-[10px] font-semibold justify-center flex items-center'>
              {unReadNums >= 100 ? '100+' : unReadNums}
            </div>
          )}
        </div>
      </IconButton>
      <IconButton path='/profile'>
        <User
          className={`${firstParam == 'profile' ? 'text-[#111111]' : 'text-[#919191]'}`}
        />
      </IconButton>
    </div>
  )
}

export default React.memo(BottomBar)

const IconButton = ({
  children,
  path,
}: {
  children: React.ReactNode
  path: string
}) => {
  const navigate = useNavigate()

  return (
    <button
      className='flex items-center justify-center transition duration-200 py-1 sm:px-1 ease-in-out transform active:scale-[90%] hover:bg-slate-100 rounded-[6px]'
      onClick={() => {
        navigate(path)
      }}
    >
      {children}
    </button>
  )
}
