import React, { useEffect, useState } from 'react'
import PersonaComments from '../components/organisms/PersonaComments'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserStore } from '../store/useUserStore'
import { ChevronLeft, ListFilter, Settings } from 'lucide-react'
import { UserService } from '../services/user-service'
import ProfileTop from '../components/organisms/ProfileTop'
import { BlockService } from '../services/block-service'
import PersonaReplies from '../components/organisms/PersonaReplies'
import BlockButton from '../components/morecules/BlockButton'

export type UserInfoType = {
  name: string
  profileImageUrl: string
  bio: string
  isBlocked?: boolean
}

enum Categories {
  COMMENTS = 'Comments',
  REPLIES = 'Replies',
}

const PersonaProfilePage = () => {
  const [category, setCategory] = useState<Categories>(Categories.COMMENTS)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { personaId = '' } = useParams()
  const { userId } = useUserStore()
  const [userInfo, setUserInfo] = useState<UserInfoType>()
  const navigate = useNavigate()

  const getUserInfo = async () => {
    console.log('애초에 시작', personaId)
    const res = await UserService.GetUser('userId', personaId)
    const blockRes = await BlockService.GetBlock(
      ['blockerId', 'blockedId'],
      [userId, personaId]
    )
    console.log('blockRes : ', blockRes)

    if (res) {
      setUserInfo({
        name: res[0].name,
        profileImageUrl: res[0].profileImageUrl,
        bio: res[0].bio ?? '',
        isBlocked: blockRes[0] ? true : false,
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    getUserInfo()
  }, [personaId])

  return (
    <div>
      <div className='px-4 pb-2 pt-4 flex flex-row justify-between'>
        <div>
          <button
            onClick={() => {
              navigate(-1)
            }}
          >
            <ChevronLeft />
          </button>
        </div>
        <div>
          <button onClick={() => navigate('/setting')}>
            <ListFilter size='22' className='mt-[-2px]' />
          </button>
        </div>
      </div>
      <ProfileTop userInfo={userInfo} />
      <div className='mt-4 px-4'>
        {/* <div className='mt-4 text-gray-400 text-[0.9em]'>
          <div>630 comments · 120 replies</div>
        </div> */}
        <div className='mt-4'>
          {userInfo && (
            <BlockButton
              name={userInfo.name}
              blocked={userInfo.isBlocked ? true : false}
              personaId={personaId}
            />
          )}
        </div>
      </div>
      <div className='mt-4 relative w-full'>
        <div
          style={{
            transition: '0.3s ease',
            left: category == Categories.COMMENTS ? '5%' : '50%',
          }}
          // ${category == Categories.COMMENTS ? 'left-0' : 'right-0'}
          className={` border-black border-b-[1px] absolute bottom-0 w-[45vw] max-w-[270px]`}
        ></div>
        <div className='flex flex-row w-full justify-between items-center px-6 border-gray-300 border-b-[1px]'>
          {Object.values(Categories).map((item) => {
            return (
              <div
                key={item}
                onClick={() => {
                  setCategory(item)
                }}
                style={{
                  transition: '0.3s ease',
                }}
                className={`${item == category ? 'text-black' : 'text-gray-400'} cursor-pointer w-full py-2 font-semibold text-[15px] text-center`}
              >
                {item}
              </div>
            )
          })}
        </div>
      </div>
      <div className='pt-2 relative'>
        {category == Categories.COMMENTS && (
          <PersonaComments personaId={personaId} userId={userId} />
        )}
        {category == Categories.REPLIES && (
          <PersonaReplies personaId={personaId} userId={userId} />
        )}
      </div>
    </div>
  )
}

export default PersonaProfilePage
