import React from 'react'
import { useUserStore } from '../../store/useUserStore'

type ProfileIconProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

const SIZE_VALUE = {
  sm: 4,
  md: 8,
  lg: 12,
  xl: 20,
}

const ProfileIcon = ({ size = 'md' }: ProfileIconProps) => {
  const { imgUrl } = useUserStore()

  return (
    <img
      src={imgUrl.length > 5 ? imgUrl : '/img/profile.png'}
      className={`rounded-[100px] ${`w-${SIZE_VALUE[size]}`} ${`h-${SIZE_VALUE[size]}`} object-fit`}
    />
  )
}

export default React.memo(ProfileIcon)

type ProfileImageProps = {
  size?: 'sm' | 'md' | 'lg'
  imgSrc?: string
}

export const ProfileImage = ({ size = 'md', imgSrc }: ProfileImageProps) => {
  const { imgUrl } = useUserStore()

  return (
    <img
      src={imgSrc ? imgSrc : imgUrl}
      className={`rounded-full ${`w-${SIZE_VALUE[size]}`} ${`h-${SIZE_VALUE[size]}`} object-cover`}
    />
  )
}
