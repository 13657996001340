import React, { useEffect } from 'react'
import Title from '../components/atom/Title'
import { useActivities } from '../hooks/activities'
import { useUserStore } from '../store/useUserStore'
import { useInView } from 'react-intersection-observer'
import PostSkeletons from '../components/morecules/PostSkeletons'
import ContentOnActivities from '../components/morecules/ContentOnActivities'

const ActPage = () => {
  const { userId } = useUserStore()

  const {
    activities,
    hasNextPage,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  } = useActivities('user_activities', userId)

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <div>
      <div className='px-4 pt-4 pb-2'>
        <Title text='Activities' />
      </div>
      {!isLoading && activities.length == 0 && (
        <div className='sub w-full text-center pt-12 text-gray-500'>
          No activities yet
        </div>
      )}
      {!isLoading &&
        activities &&
        activities.map((cmt) => {
          return <ContentOnActivities key={cmt.commentId} content={cmt} />
        })}
      <>
        {isLoading || isFetchingNextPage ? (
          <>
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
          </>
        ) : (
          <>
            <br />
            {hasNextPage ? (
              <div className='h-10' ref={ref} />
            ) : (
              <div className='sub'></div>
            )}
          </>
        )}
      </>
    </div>
  )
}

export default ActPage
