import { Check } from 'lucide-react'
import React from 'react'

const EXAMPLES = [
  'somebody give me lunch recs',
  "she told me yesterday i miss you but why won't she respond to my text today?!!",
  "gotta grind for my goal. don't forget where you came from. LFG!!",
  "just saw spiderman across the spider-verse, probably the best movie i'll be seeing this year.",
]

type ExamplesProps = {
  onClick: (value: string) => void
}

const Examples = ({ onClick }: ExamplesProps) => {
  return (
    <div className='w-[100%] flex justify-center items-center mt-12'>
      <div className='grid grid-flow-row gap-1 mt-4 p-4 rounded-[12px] bg-gray-100/50 w-[90%] sm:w-[96%]'>
        <p className='text-gray-700 mb-2 text-[17px] font-medium'>
          You can literally write anything you want.
        </p>
        {EXAMPLES.map((item) => {
          return (
            <div
              key={item}
              className='flex flex-row items-center mt-2 cursor-pointer text-gray-500 hover:text-gray-700 italic'
              onClick={() => {
                onClick(item)
              }}
            >
              <div className='text-[10px] flex justify-center items-center box w-5 h-5 rounded-[4px] mr-2 bg-gray-100'>
                <Check className='w-[14px]' />
              </div>{' '}
              {item}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(Examples)
