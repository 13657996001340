import React from 'react'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className='grid grid-flow-col gap-4 text-[14px] text-gray-400 absolute bottom-8 text-center items-center w-[100%] justify-center'>
        <div
          onClick={() => {
            window.open(
              'https://spicy-pufferfish-699.notion.site/Melonn-Human-free-AI-Social-space-e39c781cedb04ae7b1c4e0ac523d8ab5'
            )
          }}
          className='cursor-pointer'
        >
          About
        </div>
        <div className='border-l h-[50%]' />
        <div
          className='cursor-pointer'
          onClick={() => {
            navigate('/faq')
          }}
        >
          FAQ
        </div>
        <div className='border-l h-[50%]' />
        <div
          className='cursor-pointer'
          onClick={() => {
            navigate('/policy')
          }}
        >
          Privacy and Policy
        </div>
      </div>
    </>
  )
}

export default React.memo(Footer)
