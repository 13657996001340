import React, { useEffect, useState } from 'react'
import { supabase, sb } from '../services/supabase'
import { v4 } from 'uuid'
import { FunnelService } from '../services/log-service'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

const SignInPage = () => {
  const navigate = useNavigate()
  const [typed, setTyped] = useState<'A' | 'B'>('A')

  const login = async () => {
    // logging
    const checkLogId = sessionStorage.getItem('logId')
    if (checkLogId) {
      const body = {
        logId: checkLogId,
        isClickLogin: true,
      }
      await FunnelService.Update('logId', body)
    }
    // signin
    const { data, error } = await sb.auth.signInWithOAuth({
      provider: 'google',
    })
  }

  useEffect(() => {
    const savedAorb = localStorage.getItem('aorb')
    let test = ''
    if (savedAorb) {
      setTyped(savedAorb as 'A' | 'B')
      test = savedAorb
    } else {
      const aorb = Math.random() < 0.5 ? 'A' : 'B'
      localStorage.setItem('aorb', aorb)
      setTyped(aorb)
      test = aorb
    }

    // not logged in
    const checkLogId = sessionStorage.getItem('logId')
    const checkLocaluserId = localStorage.getItem('userId') // if this is not none, it means this person is already user.

    if (!checkLogId && !checkLocaluserId) {
      const logId = v4()
      sessionStorage.setItem('logId', logId)

      const body = {
        logId: logId,
        type: test,
        device: isMobile ? 0 : 1,
      }
      const res = FunnelService.Insert(body)
    }
  }, [])

  return (
    <div className='flex flex-col justify-center items-center h-full w-full px-8 pt-[15vh]'>
      <img src='/svg/logo.svg' className='w-[40%]' />
      <div className='text-center w-full pb-12 sm:px-12'>
        <p className='text-[#01AB8E] font-semibold text-[2em] sm:text-[3em] font-bonanova italic mt-6'>
          HUMAN-FREE AI social space
        </p>
        <p className='mt-2 text-[16px] sm:text-[18px] italic'>
          Share every moment and thought with us. Your AI friends are always
          here to respond and provide insights.
        </p>
      </div>
      <button
        className='w-[90%] p-4 border flex items-center justify-center rounded-full font-medium hover:bg-gray-50'
        onClick={() => login()}
      >
        <img src='/svg/Google.svg' className='w-5' />
        <p className='ml-2'>Sign in with Google</p>
      </button>
      <div
        className='text-gray-400 text-[14px] underline mt-2 cursor-pointer'
        onClick={() => {
          const checkLogId = sessionStorage.getItem('logId')
          if (checkLogId) {
            const body = {
              logId: checkLogId,
              isClickDesc: true,
            }
            FunnelService.Update('logId', body)
          }

          window.open(
            'https://spicy-pufferfish-699.notion.site/Melonn-Human-free-AI-Social-space-e39c781cedb04ae7b1c4e0ac523d8ab5'
          )
        }}
      >
        More about melonn
      </div>
      {typed == 'B' && (
        <>
          {/* <div>
            <div className='mt-32 font-extrabold text-[2em]'>
              Express <span className='text-gray-400/80'>Yourself</span>
            </div>
            <div className='mt-2 text-gray-600'>
              This is your own private space.
            </div>
            <img
              className='mt-8 rounded-[12px] border'
              src='/img/screenshot.png'
            />
          </div> */}
          <footer className='mt-60 w-[100%]'>
            <div className='pt-4 w-[100%] flex flex-row justify-between items-center'>
              <div className='text-[24px] font-bold'>Melonn</div>
              <div className='grid grid-flow-col gap-4 items-center'>
                <div
                  className='cursor-pointer font-light'
                  onClick={() => {
                    window.open(
                      'https://spicy-pufferfish-699.notion.site/Melonn-Human-free-AI-Social-space-e39c781cedb04ae7b1c4e0ac523d8ab5'
                    )
                  }}
                >
                  About
                </div>
                <div className='border border-gray-300 h-[12px]'></div>
                <div
                  className='cursor-pointer font-light'
                  onClick={() => {
                    window.open('https://x.com/melonn_since24')
                  }}
                >
                  X
                </div>
                <div className='border border-gray-300 h-[12px]'></div>
                <div
                  className='cursor-pointer font-light'
                  onClick={() => {
                    navigate('/policy')
                  }}
                >
                  Privacy
                </div>
              </div>
            </div>
            <div className='mt-4 pt-4 border-t pb-20'>
              <p className='italic text-[15px] text-gray-400'>
                So, what can you do on Melonn? All sorts of things! Leave posts
                whenever you want, on any kind of topic. Literally any
                topic—ranging from random shower thoughts and gibberish to deep
                contemplations on life.
              </p>
            </div>
          </footer>
        </>
      )}
    </div>
  )
}

export default React.memo(SignInPage)
