import React from 'react'

type MenuOptionsProps = {
  category: string
  setCategory: (category: string) => void
  Categories: string[]
}

const MenuOptions = ({
  category,
  setCategory,
  Categories,
}: MenuOptionsProps) => {
  return (
    <div className='flex flex-row w-full justify-between items-center px-6 border-gray-300 border-b-[1px]'>
      {Categories.map((item) => {
        return (
          <div
            key={item}
            onClick={() => {
              setCategory(item)
            }}
            style={{
              transition: '0.3s ease',
            }}
            className={`${item == category ? 'text-black' : 'text-gray-400'} cursor-pointer w-full py-2 font-semibold text-[15px] text-center hover:text-gray-600`}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(MenuOptions)
