import { UserDisplayType } from '../../services/user-service/types'
import React, { useCallback } from 'react'
import ProfileIcon, { ProfileImage } from '../atom/ProfileIcon'
import BlockButton from './BlockButton'
import { useNavigate } from 'react-router-dom'
import { useFriendStore } from '../../store/useFriendsStore'

type FriendRowProps = {
  user: UserDisplayType
}

const FriendRow = ({ user }: FriendRowProps) => {
  const navigate = useNavigate()
  const { friends, setFriends } = useFriendStore()

  const onBlock = useCallback((body: any) => {
    const newFriends = friends.map((item) => {
      if (item.userId == user.userId) {
        if (body) {
          return {
            ...item,
            block: [
              {
                ...body,
              },
            ],
          }
        } else {
          return {
            ...item,
            block: undefined,
          }
        }
      } else {
        return { ...item }
      }
    })

    setFriends(newFriends)
  }, [])

  return (
    <div className='flex flex-row items-start justify-between w-full py-[6px]'>
      <div
        className='min-w-[52px] pt-2 cursor-pointer'
        onClick={() => {
          navigate(`/profile/${user.userId}`)
        }}
      >
        <ProfileImage imgSrc={user.profileImageUrl} />
      </div>
      <div className='flex flex-row w-full items-start justify-between border-b-[0.8px] pb-4 border-b-gray-200'>
        <div
          className='cursor-pointer'
          onClick={() => {
            navigate(`/profile/${user.userId}`)
          }}
        >
          <div className='font-semibold'>{user.name}</div>
          <div className='text-gray-400 text-[15px]'>{user.bio}</div>
        </div>
        <div className='w-[80px] mt-2'>
          <BlockButton
            onBlock={onBlock}
            blocked={user.block && user.block.length > 0 ? true : false}
            personaId={user.userId}
            name={user.name}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(FriendRow)
