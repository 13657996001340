import { create } from 'zustand'
import { UserDisplayType } from '../services/user-service/types'

export type FriendState = {
  friends: UserDisplayType[] // 얘는 어디 쓰더라?
  setFriends: (friends: UserDisplayType[]) => void
}

export const useFriendStore = create<FriendState>((set) => ({
  friends: [],
  setFriends: (by) => {
    set((state) => ({ ...state, friends: by }))
  },
}))
