import { useInfiniteQuery } from 'react-query'
import { useMemo } from 'react'
import {
  CommentColumns,
  CommentOnlyType,
} from '../services/comment-service/types'
import { CommentService, limitLength } from '../services/comment-service'

export function useActivities(qKey: string, userId: string) {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery<CommentOnlyType[]>({
    queryKey: [qKey],
    queryFn: async ({ pageParam = 1 }) => {
      if (pageParam == 1) {
        const res = await CommentService.GetUnreadActivities(userId)
        if (res.length != 0) return res
      }
      const res = await CommentService.GetActivities(userId, pageParam)

      return res
    },
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length + 1

      //상품이 0개이거나 rowsPerPage보다 작을 경우 마지막 페이지로 인식한다.
      return lastPage?.length === 0 || lastPage?.length < limitLength
        ? undefined
        : nextPage
    },
    enabled: true,
  })

  const activities = useMemo(() => {
    if (data) return data.pages.flat()
    else return []
  }, [data])

  return {
    activities,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  }
}
